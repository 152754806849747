import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-line-clamp',
  templateUrl: './line-clamp.component.html',
  styleUrls: ['./line-clamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineClampComponent implements AfterViewChecked {
  @ViewChild(NgbTooltip, { read: ElementRef, static: true }) private readonly outlet?: ElementRef<HTMLElement>;

  @Input() content: string;

  constructor(private readonly el: ElementRef, private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  get overflown(): boolean {
    if (!this.outlet) {
      return false;
    }

    const { scrollWidth } = this.outlet.nativeElement;
    const { clientWidth } = this.el.nativeElement;

    return scrollWidth - clientWidth > 0;
  }
}
