import { TradeInfo, User } from './user.model';
import { EmployeeOrderType } from '../enums/employee-order-type.enum';

export class Employee extends User {
  children?: Array<Employee>;
  callback?: string;
  trade_info?: TradeInfo;
  number_of_employees?: number;
  total_number_of_employees?: number;
  sort_weight?: number;
  order_type?: EmployeeOrderType;
  level?: number;
  personal_id?: number;
  parents_ids: number[];
  max_child_level?: number;

  constructor(data = {} as any, parent?: User) {
    super(data);

    if (this.children) {
      this.nestedToObject(this.children);
    }

    if (parent && this.parent_id) {
      delete this.parent;
      this.parent = parent;
    }
  }

  nestedToObject(employeesList: Array<any>) {
    this.children = employeesList.map((emp) => new Employee(emp, this));
  }
}
