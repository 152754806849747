import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

import { CalendarService } from '@app/shared/services/calendar/calendar.service';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';

import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import {
  EventFilterModel,
  EventModel,
  STATE,
} from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@app/shared/constants/date.constants';
import { TYPE_SELECT } from '@app/shared/components/calendar-widget/constants/select-date.constants';
import { POSITION } from '@app/shared/components/move-pin/constants/move-pin.constants';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: ['./calendar-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarWidgetComponent implements OnInit {
  @Input() id;
  @Input() dpID: string;
  @Input() controlName = '';
  @Input() startValue = '';
  @Input() forPlanner = false;
  @Input() fillTitle = false;

  @Input() minDate: moment.Moment | null = null;
  @Input() maxDate: moment.Moment | null = null;

  date = moment().locale('ru');
  monthNumber: number = +moment().format('M');
  year: number = +moment().format('YYYY');
  startDayOfTheWeekNumber: number = moment().locale('ru').startOf('month').weekday();
  events: Object[] = [];
  eventForDay = {};
  calendarData: Object[] = [];
  currentYear: string = moment().locale('ru').format('YYYY');
  currentMonth: string = moment().locale('ru').format('MMMM');
  nowMonth: string = moment().locale('ru').format('MMMM');
  nowWeek: number = moment().locale('ru').isoWeek();
  nowDayWeekNumber: number = moment().locale('ru').day();
  currentDayOnCalendar = false;
  typeSelect = TYPE_SELECT;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('calendarMain') private calendarMain: ElementRef;

  constructor(
    private calendarService: CalendarService,
    private calendarDataService: CalendarDataService,
    private modalInjectorService: ModalInjectorService
  ) {}

  ngOnInit(): void {
    this.startValue = this.startValue.substr(0, 10);
    if (this.startValue && moment(this.startValue, DATE_FORMAT).isValid()) {
      const startDate = moment(this.startValue, DATE_FORMAT);
      this.currentYear = startDate.format('YYYY');
      this.currentMonth = startDate.format('MMMM');
    }
    this.updateCalendar();
    this.calendarDataService.updateDataEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!event.id) {
        this.updateCalendar();
      }
      if (event.id === this.id) {
        switch (event.type) {
          case TYPE_SELECT.YEAR:
            this.currentYear = event.value;
            break;
          case TYPE_SELECT.MONTH:
            this.currentMonth = event.value;
            break;
        }
        this.updateCalendar();
      }
    });
    this.calendarDataService.updateDataAllEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.updateCalendar();
    });
  }

  openMonthPlanner(): void {
    this.calendarDataService.openMonthPlanner({ month: this.currentMonth, year: this.currentYear });
  }

  changeMonth(difference): void {
    const nextMonth = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').add(difference, 'month');
    this.currentYear = nextMonth.format('YYYY');
    this.currentMonth = nextMonth.format('MMMM');
    this.updateCalendar();
  }

  showSelect(type: TYPE_SELECT, event): void {
    const elemWidget = this.calendarMain.nativeElement.offsetParent,
      elem = event.target,
      parent = {
        top: elemWidget.offsetTop + elem.offsetTop + 20,
        left: elemWidget.offsetLeft + elem.offsetLeft + elem.offsetParent.offsetLeft + 15,
        height: elem.offsetHeight,
        width: elem.offsetWidth,
      },
      countWeek = moment(this.currentYear, 'YYYY').endOf('year').isoWeek();
    this.modalInjectorService.addModal(ModalInjectorConstants.SELECT_DATE, {
      type,
      parent,
      countWeek,
      parentId: this.id,
    });
  }

  updateCalendar(): void {
    this.currentDayOnCalendar = false;
    const startDate = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').startOf('week');
    const startDateEvent = startDate.format(BACKEND_DATE_FORMAT);
    this.calendarData = [];
    let week = [],
      numberWeek = 0;

    for (let i = 0; i < 6; i++) {
      numberWeek = startDate.isoWeek();
      for (let j = 0; j < 7; j++) {
        const maxDateDisabled = this.maxDate ? startDate.isAfter(this.maxDate, 'day') : false;
        const minDateDisabled = this.minDate ? this.minDate.isAfter(startDate, 'day') : false;

        const day: any = {
          dayNumber: startDate.format('D'),
          day: startDate.format(BACKEND_DATE_FORMAT),
          dayForDatepicker: startDate.format(DATE_FORMAT),
          maxDateDisabled,
          minDateDisabled,
          class: 'calendar-day',
          hasEvent: false,
          events: [],
        };
        if (this.dpID && !this.forPlanner && day.dayForDatepicker === this.startValue) {
          day.class += ' calendar-day-select';
        }
        if (+moment().startOf('day') === +startDate) {
          day.class += ' calendar-day-current';
          this.currentDayOnCalendar = true;
        }
        if (this.currentMonth !== startDate.format('MMMM')) {
          day.class += ' calendar-day-old_month';
        }
        startDate.add(1, 'day');
        week.push(day);
      }
      this.calendarData.push({ number: numberWeek, days: week });
      week = [];
    }
    const endDateEvent = startDate.format(BACKEND_DATE_FORMAT);

    if (!this.dpID) {
      const filterData: EventFilterModel = {
        startDate: startDateEvent,
        endDate: endDateEvent,
      };
      this.calendarDataService.getEventsByPeriod(filterData).subscribe((res: EventModel[]) => {
        this.events = res;
        this.updateEvent();
      });
    }
  }

  selectAction(day) {
    if (this.dpID) {
      this.selectDate(day);
    } else if (this.eventForDay[day.day]) {
      this.infoForDay(day);
    } else this.addEvent(day.dayForDatepicker);
  }

  selectDate(day): void {
    this.calendarDataService.updateDatepicker({ id: this.dpID, value: day.dayForDatepicker });
    this.modalInjectorService.closeModal(this.id);
  }

  updateEvent(): void {
    this.eventForDay = {};
    if (Array.isArray(this.events)) {
      this.events.forEach((item) => {
        if (this.eventForDay[item['start_date']] !== 1) {
          // tslint:disable-next-line:no-bitwise
          if (~[STATE.CANCELLED, STATE.EXECUTED].indexOf(item['status'])) {
            this.eventForDay[item['start_date']] = 2;
          } else {
            this.eventForDay[item['start_date']] = 1;
          }
        }
      });
    }
  }

  infoForDay(day): void {
    const parent = this.forPlanner
      ? this.calendarMain.nativeElement.getBoundingClientRect()
      : this.calendarMain.nativeElement.offsetParent.getBoundingClientRect();
    this.modalInjectorService.addModal(ModalInjectorConstants.INFO_FOR_DAY, {
      day: day.day,
      dayForDatepicker: day.dayForDatepicker,
      parent,
      position: this.forPlanner ? POSITION.CENTER : null,
    });
  }

  addEvent(day): void {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { show: true, addEvent: true, day });
  }
}
