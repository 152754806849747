import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';

import { combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import {
  CEIL_SLIDER_OPTION,
  FLOOR_SLIDER_OPTION,
} from '@app/shared/components/form/form-range-slider/form-range-slider.constants';
import { DestroyService } from '@app/services/destroy.service';
/**
 * @deprecated use FormRangeInputComponent
 */
@Component({
  selector: 'app-form-range-slider',
  templateUrl: './form-range-slider.component.html',
  styleUrls: ['./form-range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRangeSliderComponent implements OnInit {
  @Input() minRange: number = null;
  @Input() maxRange: number = null;
  @Output() changeRange = new EventEmitter();
  @Output() onInvalidForm = new EventEmitter();

  private _readonly = false;
  public hasError = false;
  private isProcess = false;

  form: FormGroup = new FormGroup({
    inputMinValue: new FormControl(0),
    inputMaxValue: new FormControl(0),
    sliderValue: new FormControl([0, 0]),
  });

  options: Options = {
    step: 0.0001,
    floor: FLOOR_SLIDER_OPTION,
    ceil: CEIL_SLIDER_OPTION,
    enforceStep: false,
    enforceRange: false,
    translate: (): string => {
      return '';
    },
    combineLabels: (): string => {
      return '';
    },
  };

  @Input() set setValueFrom(value: number) {
    const inputValue = value === null ? '' : value;
    this.inputMinControl.setValue(inputValue, { emitEvent: false });
  }

  @Input() set setValueTo(value: number) {
    const inputValue = value === null ? '' : value;
    this.inputMaxControl.setValue(inputValue, { emitEvent: false });
  }

  @Input() set setSliderValue(sliderValue: number[]) {
    if (sliderValue) {
      this.sliderControl.setValue(sliderValue);
    }
  }

  @Input() set step(step: number) {
    this.options = {
      ...this.options,
      step: step,
    };
  }

  @Input() set readonly(value: boolean) {
    this._readonly = value;
    this.options = {
      ...this.options,
      readOnly: value,
      disabled: value,
    };
  }

  get inputMinControl() {
    return this.form.get('inputMinValue');
  }

  get inputMaxControl() {
    return this.form.get('inputMaxValue');
  }

  get sliderControl() {
    return this.form.get('sliderValue');
  }

  get readonly(): boolean {
    return this._readonly;
  }

  constructor(private destroy$: DestroyService) {}

  ngOnInit() {
    const newOptions: Options = Object.assign({}, this.options);
    if (this.minRange !== null) {
      newOptions.floor = this.minRange;
    }

    if (this.maxRange !== null) {
      newOptions.ceil = this.maxRange;
    }

    this.options = newOptions;

    this.sliderControl.valueChanges
      .pipe(startWith([this.inputMinControl.value, this.inputMaxControl.value]), takeUntil(this.destroy$))
      .subscribe(([min, max]) => {
        if (this.hasError && !this.isProcess) {
          this.inputMinControl.setValue(this.minRange, { emitEvent: false });
          this.inputMaxControl.setValue(this.maxRange, { emitEvent: false });
        }
        this.changeRange.emit({ min: parseFloat(min) || '', max: parseFloat(max) || '' });
      });

    combineLatest([
      this.inputMinControl.valueChanges.pipe(startWith([this.inputMinControl.value])),
      this.inputMaxControl.valueChanges.pipe(startWith([this.inputMaxControl.value])),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((minMax) => {
        this.sliderControl.setValue(minMax, { emitEvent: false });
      });

    const { inputMinValue, inputMaxValue } = this.form.value;

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.onInvalidForm.emit(!inputMinValue || !inputMaxValue || inputMinValue > inputMaxValue);

      if (inputMinValue === '' && inputMaxValue === '') {
        this.form.patchValue({
          inputMinValue: this.minRange,
          inputMaxValue: this.maxRange,
        });

        this.options.floor = 1;
        this.options.ceil = this.maxRange;
      } else {
        if (inputMinValue === '' && inputMaxValue !== '') {
          if (inputMaxValue >= this.minRange && inputMaxValue > 10) {
            this.options.floor = 1;
          } else {
            this.options.floor = this.minRange;
          }
          this.options.ceil = inputMaxValue > this.minRange ? +inputMaxValue : this.maxRange;
        }

        if (inputMinValue !== '' && inputMaxValue === '') {
          this.options.floor = 1;
          this.options.ceil = this.maxRange;
        }
      }

      if (this.inputMinControl.value > this.inputMaxControl.value) {
        this.hasError = true;
      }

      this.onInvalidForm.emit(
        (this.inputMinControl.value < this.minRange &&
          this.inputMinControl.value !== '' &&
          this.inputMaxControl.value !== '') ||
          this.inputMinControl.value === '' ||
          this.inputMaxControl.value === '' ||
          this.inputMinControl.value > this.inputMaxControl.value
      );
    });
  }

  controlFocus($event: FocusEvent = null, fieldName: string) {
    this.isProcess = true;
    if (this._readonly) {
      return;
    }
    if (!this[fieldName]) {
      this[fieldName] = '';
    }

    this.onInvalidForm.emit(this.inputMinControl.value < this.minRange);
  }

  controlBlur($event: FocusEvent = null, fieldName: string) {
    this.hasError = false;
    this.isProcess = false;
    if (this._readonly) {
      return;
    }

    this.hasError = false;

    if (!this[fieldName]) {
      this[fieldName] = '';
      if (
        this.inputMinControl.value !== '' &&
        this.inputMinControl.value < this.inputMaxControl.value &&
        this.inputMinControl.value >= this.minRange
      ) {
        return;
      }
      this.options.floor = this.minRange;
    }

    this.hasError =
      this.inputMinControl.value < this.minRange &&
      this.inputMinControl.value !== '' &&
      this.inputMaxControl.value !== '';

    if (this.inputMinControl.value > this.inputMaxControl.value) {
      this.hasError = true;
      this.form.patchValue({
        inputMinValue: this.minRange,
        inputMaxValue: this.maxRange,
      });
    } else this.hasError = false;

    this.onInvalidForm.emit(
      this.inputMinControl.value < this.minRange || this.inputMaxControl.value < this.inputMinControl.value
    );
  }

  isNumberKey(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
