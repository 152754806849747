import { Component, Input, OnInit } from '@angular/core';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-change-tso',
  templateUrl: './change-tso.component.html',
  styleUrls: ['./change-tso.component.sass'],
})
export class ChangeTsoComponent implements OnInit {
  @Input() user: User = {} as User;
  availableTso: User[] = [];
  currentTso: User = {} as User;
  isShowed = false;
  selectedTso: User = {} as User;

  constructor(private personalDataService: PersonalDataService) {
    this.getCurrentTso();
    this.getAvailableTso();
  }

  ngOnInit() {}

  openTsoEdit() {
    this.isShowed = true;
  }

  getAvailableTso() {
    // added params to getReadyTso() {is_ready_tso: true} or {is_tso: true}
    this.personalDataService.getReadyTso().subscribe((data) => {
      this.availableTso = data.filter((user) => user.id !== this.user.id);
    });
  }

  closeTsoEdit() {
    this.isShowed = false;
  }

  changeTso() {
    const id = this.selectedTso.id ? this.selectedTso.id : null;
    this.personalDataService.bindTsoForUser(id).subscribe((user) => {
      this.currentTso = user.tso;
    });

    this.closeTsoEdit();
  }

  changeSelection(user: User) {
    this.selectedTso = user;
  }

  getCurrentTso() {
    this.personalDataService.getSelfTso().subscribe((user) => {
      this.currentTso = user;
    });
  }
}
