import { ElementRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';
import { isNaN } from 'lodash';

@Directive({
  selector: '[maxValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxValueDirective,
      multi: true,
    },
  ],
})
export class MaxValueDirective {
  @Input('maxValue') maxValue: number;
  @Input('isStrict') isStrict = false;

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { maxValue: { valid: false } };

    if (this.maxValue && +v > +this.maxValue && +v !== null) {
      return invalid;
    }

    return null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isStrict || this.specialKeys.includes(event.key)) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const selectedText = this.getSelectedText(event.target as HTMLInputElement);
    const next: string = selectedText ? current.replace(selectedText, event.key) : current.concat(event.key);

    if (next && (isNaN(+next) || +next > this.maxValue)) {
      event.preventDefault();
    }
  }

  private getSelectedText(elem: HTMLInputElement) {
    return elem.value.substring(elem.selectionStart, elem.selectionEnd);
  }
}
