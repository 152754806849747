<div>
  <div class="mb-4" *ngIf="filterSuggestions?.length > 0 || isObservable">
    <ng-container *ngIf="isRadioSelect">
      <app-radio-filter-livesearch
        #livesearchRadio
        [placeholder]="placeholder"
        [list]="filterSuggestions"
        [title]="title"
        [template]="template"
        (searchEvent)="searchEvent.next({ value: $event })"
        (selectEvent)="select($event)"
        (onDisabledButton)="setDisabledButton($event)"
      >
      </app-radio-filter-livesearch>
    </ng-container>

    <ng-container *ngIf="!isRadioSelect">
      <app-multiple-livesearch-select
        #livesearchSelect
        [placeholder]="placeholder"
        [list]="filterSuggestions"
        [title]="title"
        [isNumber]="isNumber"
        [template]="template"
        (searchEvent)="searchEvent.next({ value: $event })"
        (selectEvent)="select($event)"
        (onDisabledButton)="setDisabledButton($event)"
      >
      </app-multiple-livesearch-select>
    </ng-container>
  </div>

  <div class="d-flex justify-content-end">
    <button
      class="btn btn-dc-primary"
      appTestLocator="multiple-search__confirm"
      [disabled]="isApplyDisable || isDisabledButton"
      (click)="apply()"
    >
      Применить
    </button>
  </div>
</div>
