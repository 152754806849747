import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { MultipleLivesearchSelectComponent } from '../multiple-livesearch-select/multiple-livesearch-select.component';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';

@Component({
  selector: 'app-multiple-search-text',
  templateUrl: './multiple-search-text.component.html',
  styleUrls: ['./multiple-search-text.component.scss'],
  providers: [FilterPipe],
})
export class MultipleSearchTextComponent {
  isDisabledButton = true;

  @ViewChild('livesearchSelect') livesearchSelect: MultipleLivesearchSelectComponent;
  @ViewChild('livesearchRadio') livesearchRadio: MultipleLivesearchSelectComponent;

  @Input() template: TemplateRef<any>;
  @Input() filterSuggestions: MultipleSearchSuggestion[];
  @Input() placeholder: string = '';
  @Input() title: string = 'Все';
  @Input() isNumber: boolean;
  @Input() isRadioSelect = false;
  @Input() isObservable = false;

  @Output() searchEvent = new EventEmitter<{ value: string }>();
  @Output() submitEvent = new EventEmitter<{ value: MultipleSearchSuggestion[] }>();
  @Output() filterSuggestionsEmit = new EventEmitter<{ value: MultipleSearchSuggestion[] }>();

  @Input() set filteredValue(ids: (number | string)[]) {
    if (!ids || Object.values(ids).every((value) => value === undefined)) return;

    this.filterSuggestions = this.filterSuggestions.map((suggestion) => ({
      ...suggestion,
      value: !!ids && ids.includes(suggestion.id),
    }));
  }

  constructor(private filterPipe: FilterPipe<MultipleSearchSuggestion>) {}

  get isApplyDisable() {
    if (this.livesearchSelect?.allChecked) {
      return false;
    }

    return !!this.livesearchSelect && !this.livesearchSelect?.partiallySelected;
  }

  apply() {
    const { list, searchValue } = this.livesearchSelect ? this.livesearchSelect : this.livesearchRadio;

    this.submitEvent.emit({ value: this.filterPipe.transform(list, searchValue, this.getSearchedField()) });
  }

  select(value: MultipleSearchSuggestion[]) {
    this.filterSuggestions = value;
    this.filterSuggestionsEmit.emit({ value });
  }

  private getSearchedField(): string {
    const firstItem = this.filterSuggestions[0];
    return firstItem.config?.searchedFieldName ? firstItem.config?.searchedFieldName : 'label';
  }

  setDisabledButton(value: boolean): void {
    this.isDisabledButton = value;
  }
}
