import { PaymentRegistryColumn } from '../models/payment-registry-column.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';

/** настройка колонок реестра оплат */
export const paymentRegistryColumns: PaymentRegistryColumn[] = [
  {
    id: 'reporting_period',
    label: 'Отчетный период',
    width: '140px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'id_trade',
    label: 'ID торгов',
    width: '140px',
  },
  {
    id: 'structure_ap',
    label: 'Структура АП',
    width: '250px',
  },
  {
    id: 'payer',
    label: 'Плательщик',
    width: '250px',
  },
  {
    id: 'customer_trade',
    label: 'Заказчики торгов',
    width: '250px',
  },
  {
    id: 'responsible_for_trade',
    label: 'Ответственные за торги',
    width: '250px',
  },
  {
    id: 'company_statuses',
    label: 'Статусы компаний',
    width: '136px',
    filter: 'filter',
  },
  {
    id: 'status_history',
    label: 'История статусов',
    width: '110px',
    filter: 'none',
  },
  {
    id: 'removing_restrictions',
    label: 'Снятие ограничений',
    width: '220px',
    filter: 'filter',
  },
  {
    id: 'comment',
    label: 'Комментарии',
    width: '166px',
    filter: 'filter',
  },
  {
    id: 'tariff_name',
    label: 'Название тарифа',
    width: '196px',
  },
  {
    id: 'tariff_one_lot',
    label: 'Тариф за 1 лот (Р)',
    width: '200px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'customer_discount',
    label: 'Скидка по промо-коду (Р)',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'completed_trade',
    label: 'Завершенные торги',
    width: '176px',
    filter: 'filter',
    // filterKeys: ['from_completed_trade', 'to_completed_trade', 'from_completed_lot', 'to_completed_lot'],
    filterKeys: ['from_completed_lot', 'to_completed_lot'],
  },
  {
    id: 'customer_payments',
    label: 'Суммы к оплате клиентами (Р)',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'documents',
    label: 'Документы',
    filter: 'none',
  },
  {
    id: 'payment_dates',
    label: 'Даты оплат',
    width: '116px',
    filter: 'none',
  },
  {
    id: 'pp',
    label: '№ ПП.',
    filter: 'none',
  },
  {
    id: 'amount_paid',
    label: 'Оплаченная сумма (Р)',
    width: '156px',
    filter: 'none',
  },
  // TODO: Удален фильтр (причина - нет на беке)
  {
    id: 'customer_balance',
    label: 'Баланс клиентов',
    width: '126px',
    filter: 'none',
  },
  {
    id: 'agent_bonus_lot',
    label: 'Бонус агента за 1 лот (Р)',
    width: '166px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'ndfl_lots',
    label: 'НДФЛ (за лоты)',
    width: '134px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'calculation_bonus_lots',
    label: 'Расчет бонуса за лоты (Р)',
    width: '176px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonuses_lots_pay',
    label: 'Бонусы за лоты к оплате (Р)',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonus_percentage_lots',
    label: '% бонусов за лоты к оплате',
    width: '176px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'id_agent_lot',
    label: 'ID агента за лоты',
    width: '146px',
  },
  {
    id: 'fio_agent_lot',
    label: 'ФИО агента за лоты',
    width: '156px',
  },
  {
    id: 'bonuses_agent_otp',
    label: 'Бонус агента за ОТП (Р)',
    width: '166px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'ndfl_otp',
    label: 'НДФЛ (за ОТП)',
    width: '126px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'calculation_bonus_otp',
    label: 'Расчет бонуса за ОТП (Р)',
    width: '176px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonuses_otp_pay',
    label: 'Бонусы за ОТП к оплате (Р)',
    width: '176px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonus_percentage_otp',
    label: '% бонусов за ОТП к оплате',
    width: '166px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'id_agent_otp',
    label: 'ID агента-ОТП',
    width: '146px',
  },
  {
    id: 'fio_agent_otp',
    label: 'ФИО агента-ОТП',
    width: '156px',
  },
  {
    id: 'bonus_percent_agent_net',
    label: 'Бонус агента за сеть (%)',
    width: '166px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'ndfl_net',
    label: 'НДФЛ (за сеть)',
    width: '136px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'calculation_bonus_net',
    label: 'Расчет бонуса за сеть (Р)',
    width: '176px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonuses_net_pay',
    label: 'Бонусы за сеть к оплате (Р)',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonus_percentage_net',
    label: '% бонусов за сеть к оплате',
    width: '166px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'id_agent_net',
    label: 'ID агента за сеть',
    width: '136px',
  },
  {
    id: 'fio_agent_net',
    label: 'ФИО агента за сеть',
    width: '156px',
  },
  {
    id: 'ndfl',
    label: 'Сумма НДФЛ (Р)',
    width: '136px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'amount_calculated_bonuses',
    label: 'Сумма рассчитанных бонусов (Р)',
    width: '226px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'agent_bonus_amount_payable',
    label: 'Сумма бонусов к оплате (Р)',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonus_amount_payable_minus_ndfl',
    label: 'Бонус к оплате с вычетом НДФЛ (Р)',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'percentage_all_bonuses_payable',
    label: '% всех бонусов к оплате',
    width: '186px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
  {
    id: 'bonuses_paid_agent',
    label: 'Сумма выплаченных агенту бонусов (Р)',
    width: '226px',
    filter: 'none',
    // filterKeys: 'fromTo',
  },
  // TODO: для агента добавить фильтр
  {
    id: 'payout_date',
    label: 'Дата последней выплаты',
    width: '226px',
    filter: 'none',
  },
  {
    id: 'agent_balance',
    label: 'Общий баланс агентов (Р)',
    width: '176px',
    filter: 'filter',
    filterKeys: 'fromTo',
  },
];

/**
 * NO CHECK
 */
export const paymentRegistryColumnsPartner: string[] = [
  'reporting_period',
  'id_trade',
  'structure_ap',
  'payer',
  'customer_trade',
  'responsible_for_trade',
  'company_statuses',
  'status_history',
  'removing_restrictions',
  'comment',
  'tariff_name',
  'tariff_one_lot',
  'customer_discount',
  'completed_trade',
  'customer_payments',
  // 'amount_paid', - в ТЗ нет этой колонки 13.03
  'customer_balance',
  'agent_bonus_lot',
  'calculation_bonus_lots',
  'bonuses_lots_pay',
  'bonus_percentage_lots',

  'bonus_agent_payout_amount_lots',
  'bonus_payout_date_lots',
  'balance_for_bonus_lots',

  'bonuses_agent_otp',
  'calculation_bonus_otp',
  'bonuses_otp_pay',
  'bonus_percentage_otp',
  'bonus_percent_agent_net',
  'calculation_bonus_net',
  'bonuses_net_pay',
  'bonus_percentage_net',
  'id_agent_net',
  'fio_agent_net',
  'bonus_agent_payout_amount_net',
  'bonus_payout_date_net',
  'balance_for_bonus_net',
  // 'ndfl',
  'amount_calculated_bonuses',
  'percentage_all_bonuses_payable',

  'bonuses_paid_agent',

  'agent_bonus_amount_payable',
  'bonus_amount_payable_minus_ndfl',

  'payout_date',
  'agent_balance',
];

export const paymentRegistryColumnsSuperuser: string[] = [
  'reporting_period',
  'id_trade',
  'structure_ap',
  'payer',
  'customer_trade',
  'responsible_for_trade',
  'company_statuses',
  'status_history',
  'removing_restrictions',
  'comment',
  'tariff_name',
  'tariff_one_lot',
  'customer_discount',
  'completed_trade',
  'customer_payments',
  'documents',
  'payment_dates',
  'pp',
  'amount_paid',
  'customer_balance',
  'agent_bonus_lot',
  'calculation_bonus_lots',
  'bonuses_lots_pay',
  'bonus_percentage_lots',
  'id_agent_lot',
  'fio_agent_lot',
  'bonuses_agent_otp',
  'calculation_bonus_otp',
  'bonuses_otp_pay',
  'bonus_percentage_otp',
  'id_agent_otp',
  'fio_agent_otp',
  'bonus_percent_agent_net',
  'calculation_bonus_net',
  'bonuses_net_pay',
  'bonus_percentage_net',
  'id_agent_net',
  'fio_agent_net',
  'amount_calculated_bonuses',
  'agent_bonus_amount_payable',
  'bonus_amount_payable_minus_ndfl',
  'percentage_all_bonuses_payable',
];

export const paymentRegistryColumnsClient: string[] = [
  'reporting_period',
  'id_trade',
  'structure_ap',
  'payer',
  'customer_trade',
  'responsible_for_trade',
  'company_statuses',
  'status_history',
  'removing_restrictions',
  'comment',
  'tariff_name',
  'tariff_one_lot',
  'customer_discount',
  'completed_trade',
  'customer_payments',
  'documents',
  'payment_dates',
  'pp',
  'amount_paid',
  'bonus_amount_payable_minus_ndfl',
  'customer_balance',
];

export const paymentRegistryColumnsByUserType = {
  [RolesEnum.PARTNER]: paymentRegistryColumnsPartner,
  [RolesEnum.ADMIN_OF_USER]: paymentRegistryColumnsClient,
  [RolesEnum.ADMIN_OF_DIRECTION]: paymentRegistryColumnsClient,
  [RolesEnum.ACCOUNTANT]: paymentRegistryColumnsSuperuser,
  [RolesEnum.SUPERUSER]: paymentRegistryColumnsSuperuser,
};
