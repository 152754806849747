import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { User } from '@app/shared/models/user.model';

import { AuthService } from '@app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { AuthorFile } from '@app/file_manager/models/author-file.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  @Input() title: string = 'Контакты';
  @Input() user: User | AuthorFile | number;
  @Input() closeButton: boolean = false;
  user$: Observable<User>;
  protected readonly RolesEnum = RolesEnum;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  get userId() {
    return +this.authService.user_id;
  }

  constructor(private authService: AuthService, private readonly activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.user$ = this.authService.getUserById(typeof this.user === 'number' ? this.user : this.user.id);
  }

  cancel(): void {
    this.activeModal.close();
  }

  getCompanyName(user: User) {
    if (user.type === RolesEnum.EXPERT || user.type === RolesEnum.SUPERUSER || user.type === RolesEnum.ACCOUNTANT) {
      return 'ООО Прямой Контакт';
    } else {
      const company = user.company.name.split(' ');
      if (company[0] === 'Физическое') {
        return 'Физическое лицо';
      }
      return `${user.company.organization_type} ${user.company.organization_name}`;
    }
  }

  maxLength(item: string, maxLength: number) {
    if (item.length <= maxLength) return true;
    else return false;
  }
}
