import { Lot, TradeProviderLot, TradeProviderLotsMap, TradeProviderNomenclature } from '../models/lots.model';
import { TradeCustomer } from '../models/customers.model';
import { Column } from '@app/shared/models/table';
import { TradeCard, TradesRequestConfig } from '../models/trades.model';
import { UserTradeSettings } from '@app/shared/models/user-settings.model';
import { SupplierTradeCard, SupplierTradeResponse } from '../models/suppliers.model';
import { getTitleRole, mapOrder } from '@app/shared/utils';
import { formatSectionsStringByOneLevel } from '@app/+competence-map/helpers/competence-map.helpers';
import { User } from '@app/shared/models/user.model';
import { LotsHelper } from './lots.helper';
import { RatingProviderTrade } from '../models/trades-rating.model';
import { TradeCompetency, TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { BACKEND_DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from '@app/shared/constants/date.constants';
import moment from 'moment';
import { ValidationErrors } from '@angular/forms';
import { DatesHelper } from '@app/shared/helpers/dates.helper';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { ProvidersCountPayload } from '@app/+competence-map/models/suppliers-selection.model';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { TradeColumnsKeys } from '@app/+trades/constants/trades-table.constants';
import { KeyValue } from '@app/shared/models/base-utility.model';
import { SliderValue } from '@app/shared/models/slider.model';
import { BaseUser } from '@app/shared/models/base-user.model';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { cloneDeep } from 'lodash-es';

export class TradesHelper {
  static getInitalLots(
    card: SupplierTradeCard,
    initProviderLotsMap = TradesHelper.getProviderLotsMap(cloneDeep(card.lots))
  ) {
    const freshLots = LotsHelper.fillServerToLots(cloneDeep(card.trade.lots).sort((a, b) => a.number - b.number));
    freshLots.forEach((lot) => lot.extra.nomenclatures.forEach((nom) => nom.criteria.sort((a, b) => a.sort - b.sort)));

    TradesHelper.writeValueToCustomerCriteria(initProviderLotsMap, freshLots);

    return freshLots;
  }

  static writeValueToCustomerCriteria(tradeProviderLots: TradeProviderLotsMap, lots: Lot[]) {
    if (!tradeProviderLots || !lots) {
      return;
    }

    lots.forEach((lot) => {
      const providerLot = tradeProviderLots.lots.get(`${lot.id}`);
      if (!providerLot || providerLot.deleted) {
        return;
      }

      lot.extra.nomenclatures.forEach((nom) => {
        const mapNomId = `${lot.id}.${nom.id}`;

        if (tradeProviderLots.nomenclatures.has(mapNomId)) {
          nom.criteria.forEach((criteria) => {
            const providerCriteria = tradeProviderLots.criteria.get(`${mapNomId}.${criteria.id}`);
            criteria.value = providerCriteria?.value ?? null;
          });
        }
      });
    });
  }

  static getProviderLotsMap(providerLotList: TradeProviderLot[]): TradeProviderLotsMap {
    if (!providerLotList?.length) {
      return null;
    }

    const providerLotsMap: TradeProviderLotsMap = {
      lots: new Map(),
      nomenclatures: new Map(),
      criteria: new Map(),
    };

    providerLotList.forEach((lot) => {
      providerLotsMap.lots.set(
        `${lot.trade_lot_id}`,
        this.getObjectWithoutProps<TradeProviderLot, 'nomenclatures_provider'>(lot, ['nomenclatures_provider'])
      );

      lot.nomenclatures_provider.forEach((nomenclature) => {
        providerLotsMap.nomenclatures.set(
          `${lot.trade_lot_id}.${nomenclature.trade_nomenclature_id}`,
          this.getObjectWithoutProps<TradeProviderNomenclature, 'criteria_provider'>(nomenclature, [
            'criteria_provider',
          ])
        );

        nomenclature.criteria_provider.forEach((criteria) =>
          providerLotsMap.criteria.set(
            `${lot.trade_lot_id}.${nomenclature.trade_nomenclature_id}.${criteria.trade_criterion_id}`,
            criteria
          )
        );
      });
    });

    return providerLotsMap;
  }

  static updateTradeProviderLots(tradeProviderLots: TradeProviderLot[], lots: Lot[]): TradeProviderLot[] {
    const orderLots = lots.map((c) => c.id);
    mapOrder(tradeProviderLots, orderLots, 'trade_lot_id');

    return tradeProviderLots.map((lot, lotIndex) => {
      lot.deleted = false;

      const orderNoms = lots[lotIndex].extra.nomenclatures.map((c) => c.id);
      mapOrder(lot.nomenclatures_provider, orderNoms, 'trade_nomenclature_id');

      lot.nomenclatures_provider.forEach((nom, nomIndex) => {
        nom.criteria_provider.forEach((criteria, criteriaIndex) => {
          const findedCriteria = lots[lotIndex].extra.nomenclatures[nomIndex].criteria.find(
            (c) => c.id === criteria.trade_criterion_id
          );

          if (!findedCriteria) {
            console.error('NOT FOUND CRITERIA');
          }

          criteria.value = findedCriteria.value;
        });
      });

      return lot;
    });
  }

  static createTradeProviderLots(lots: Lot[]): TradeProviderLot[] {
    const tradeProviderLots: TradeProviderLot[] = [];

    LotsHelper.fillIdToLots(lots);

    lots.forEach((lot, lotIndex) => {
      tradeProviderLots.push({
        trade_lot_id: lot.id,
        nomenclatures_provider: [],
      });
      lot.nomenclatures.forEach((nom, nomIndex) => {
        tradeProviderLots[lotIndex].nomenclatures_provider.push({
          trade_nomenclature_id: nom.id,
          criteria_provider: [],
        });

        nom.criteria.sort((a, b) => a.sort - b.sort);

        nom.criteria.forEach((criteria, criteriaIndex) => {
          // console.log(criteria, lot.extra.nomenclatures[nomIndex].criteria[criteriaIndex]);
          tradeProviderLots[lotIndex].nomenclatures_provider[nomIndex].criteria_provider.push({
            trade_criterion_id: criteria.id,
            value: lot.extra.nomenclatures[nomIndex].criteria.find((c) => criteria.id === c.id).value,
          });
        });
      });
    });

    return tradeProviderLots;
  }

  /**
   * Сбрасывает значения критериев для всех лотов
   * @param lots
   */
  static resetLotsValues(lots: TradeProviderLot[]): TradeProviderLot[] {
    return lots.map((lot) => {
      lot.deleted = true;
      return lot;
    });
  }

  /**
   * Форматирование процента
   * @param number
   * @returns {number}
   */
  static fixedPercent(number) {
    const result = number ? Math.round(number) : 0;

    if (!result) {
      return 0;
    }

    return result;
  }

  /**
   * Расчёт процента
   * @param numerator
   * @param denominator
   */
  static getPercent(numerator, denominator) {
    const postfix = '%';
    if (denominator === 0) {
      return `0${postfix}`;
    }
    return `${TradesHelper.fixedPercent((numerator / denominator) * 100)}${postfix}`;
  }

  /**
   * Перевод кастомеров в формат который поддерживает сервер
   * @param {TradeCustomer[]} customers
   * @returns {any[]}
   */
  static prepareUsersForSend(customers: TradeCustomer[]): any[] {
    return customers.map((customer) => {
      return {
        access_rights: customer.access_rights,
        user: {
          id: customer.user.id,
        },
      };
    });
  }

  static hasColumnFilterValue(column: Column<TradesRequestConfig>, filterConfig: TradesRequestConfig) {
    const isNotEmpty = (value: any) => value !== null && value !== '' && value !== undefined;

    const hasFilter = (filter: FlaskQueryFilter[], defaultFilter: FlaskQueryFilter) => {
      return filter?.some((f) => {
        if (column.data) {
          return (
            Object.keys(column.data).find((key) => key === f.name) &&
            (Array.isArray(f.val) ? f.val.length > 0 : isNotEmpty(f.val))
          );
        }
        return f.name === defaultFilter.name && (Array.isArray(f.val) ? f.val.length > 0 : isNotEmpty(f.val));
      });
    };

    return Object.keys(column.filterConfig || {}).some((key) => {
      if (key === 'filter') {
        return column.defaultFilterConfig.filter.some((df) => hasFilter(filterConfig.filter, df));
      }

      const value = filterConfig[key];
      return !Array.isArray(value) ? isNotEmpty(value) : value && value.length > 0;
    });
  }

  static writeColumnFilterValue(column: Column<TradesRequestConfig>, userTradeSettings: UserTradeSettings) {
    if (!column.filterConfig) {
      return column.filterConfig;
    }

    if (this.isColumnHidden(column, userTradeSettings)) {
      Object.keys(column.filterConfig).forEach((key) => (column.filterConfig[key] = null));
    }

    if (!Object.keys(userTradeSettings.filterConfig).length) {
      return column.filterConfig;
    }

    if (!this.isColumnHidden(column, userTradeSettings)) {
      Object.keys(column.filterConfig).forEach((key) => {
        column.filterConfig[key] = userTradeSettings.filterConfig[key];
      });
    }

    return column.filterConfig;
  }

  static isColumnHidden(column: Column<TradesRequestConfig>, userTradeSettings: UserTradeSettings) {
    return !userTradeSettings.columns.some((id) => id === column.id);
  }

  // check !!!
  static hasSomeCompetenciesSelected(data: TreeSectionsAndFilters[]): boolean {
    return data.some((item) => TreeHelper.flatTree(item).some((el) => el.selected));
  }

  static toggleSupplierTrades(trades: SupplierTradeCard[], value: boolean) {
    trades.forEach((trade) => {
      trade.expanded = value;

      trade.trade.lots.forEach((lot) => {
        lot.extra.expanded = value;
        lot.extra.criteriaExpanded = value;
      });
    });
  }

  static fillUsersInfo(user: User) {
    user.typeLabel = getTitleRole(user.type);
    user.competenciesString = formatSectionsStringByOneLevel(user.competence_sections, ' / ', '');
  }

  static sortRating(rating: RatingProviderTrade, lots: Lot[]) {
    rating.lots.forEach((lot, lotIndex) => {
      lot.nomenclatures.forEach((nom, nIndex) => {
        const order = lots[lotIndex].nomenclatures[nIndex].criteria.map((c) => c.id);
        mapOrder(nom.criteria, order, 'id_criterion');
      });
    });
  }

  static checkTermOfBuildingValidation(
    trade_date: Date,
    archive_date: Date,
    timeZoneOffset: number,
    trade_time?: string,
    archive_time?: string
  ): ValidationErrors | null {
    if (!trade_date || !archive_date) {
      return { formError: 'Не все обязательные поля заполонены' };
    }

    const tradeTime = trade_time ? moment(trade_time, TIME_FORMAT) : moment(trade_date, BACKEND_DATE_TIME_FORMAT);
    const archiveTime = archive_time
      ? moment(archive_time, TIME_FORMAT)
      : moment(archive_date, BACKEND_DATE_TIME_FORMAT);

    const days = moment(archive_date, DATE_FORMAT).diff(moment(trade_date, DATE_FORMAT), 'days');
    const hours = archiveTime.diff(tradeTime, 'hours');

    if (days < 0 || (days === 0 && hours <= 0)) {
      return { formError: 'Завершение торгов не может наступить раньше или одновременно с началом' };
    }

    if (days > 30 || (days === 0 && hours < 2)) {
      return { formError: 'Продолжительность торгов не может быть меньше 2-x чаcов и больше 30 суток' };
    }

    const minTradeStartDate = moment.utc().add(60, 'minutes');
    const startDateUtc = DatesHelper.getUtcDateTime(trade_date, trade_time, timeZoneOffset);

    if (minTradeStartDate.isAfter(startDateUtc, 'minutes')) {
      return { startDateError: 'Торги не могут начаться раньше чем через час от текущего времени' };
    }

    return null;
  }

  static buildPotentialProvidersPayload(card: TradeCard, nodes: TradeCompetency[]): ProvidersCountPayload {
    const payloadData = { trade_id: card.id, nodes };

    if (card.providers_filter.additional_filters) {
      Object.entries(card.providers_filter.additional_filters).forEach(([key, value]) => {
        if (value !== '') {
          payloadData[key] =
            key === 'not_natural_person' ? value : DatesHelper.fromDateToServerDate(value, DATE_FORMAT);
        }
      });
    }

    return payloadData;
  }

  static convertTreeCompetenciesToTradeCompetencies(
    treeList: TreeSectionsAndFilters[],
    trade_id: number
  ): TradeCompetency[] {
    return TreeHelper.treeToArray(treeList)
      .filter((item) => item.selected)
      .map((item) => {
        const data = {
          node_in_tree_id: item.id,
          trade_id,
        } as TradeCompetency;

        if (item.instance_filter?.type === FilterTemplateTypes.RANGE) {
          data.value_range_from = item.providerCompetency.value_range_from;
          data.value_range_to = item.providerCompetency.value_range_to;
        }

        if (item.instance_filter?.type === FilterTemplateTypes.BOOL) {
          data.value_bool = item.providerCompetency.value_bool;
        }
        return data;
      });
  }

  static getColumnNameByColumnData(column: Column<TradesRequestConfig>, filter: FlaskQueryFilter): string {
    return column.data?.[filter.name] ? (column.data[filter.name] as string) : filter.name;
  }

  // Helper methods to get filter suggestions for columns
  static getIdColumnSuggestions(rows: TradeCard[]): MultipleSearchSuggestion[] {
    return rows.reduce((suggestions: MultipleSearchSuggestion[], card: TradeCard) => {
      const suggestion: MultipleSearchSuggestion = {
        id: card.id.toString(),
        label: card.id.toString(),
        value: true,
        data: [card.title],
        config: {
          searchedFieldName: 'label',
          type: 'extraName',
        },
      };
      return [...suggestions, suggestion];
    }, []);
  }

  static getCompetenciesSuggestions(
    rows: any[],
    goods: TreeSectionsAndFilters[],
    services: TreeSectionsAndFilters[]
  ): {
    goods: TreeSectionsAndFilters[];
    services: TreeSectionsAndFilters[];
  } {
    const competenciesIdByFilteredRows = new Set<number>();
    rows.forEach((row) => {
      row.competence_sections.forEach((competenceSection) => {
        if (competenceSection.section) {
          competenciesIdByFilteredRows.add(competenceSection.section.id);
        }
      });
    });

    const filteredIds = Array.from(competenciesIdByFilteredRows);

    const filterBySectionId = (competence) => filteredIds.some((id) => id === +competence.section_id);

    return {
      goods: goods.filter(filterBySectionId),
      services: services.filter(filterBySectionId),
    };
  }

  static getSupplierIdColumnSuggestions(rows: SupplierTradeCard[]): MultipleSearchSuggestion[] {
    return rows.reduce((suggestions: MultipleSearchSuggestion[], card: SupplierTradeCard) => {
      const suggestion: MultipleSearchSuggestion = {
        id: card.trade.id.toString(),
        label: card.trade.id.toString(),
        value: true,
        data: [card.trade.title],
        config: {
          searchedFieldName: 'label',
          type: 'extraName',
        },
      };
      return [...suggestions, suggestion];
    }, []);
  }

  static getTitleColumnSuggestions(rows: TradeCard[]): MultipleSearchSuggestion[] {
    return rows.reduce((suggestions: MultipleSearchSuggestion[], card: TradeCard) => {
      const suggestion: MultipleSearchSuggestion = {
        id: card.title?.toString(),
        label: card.title,
        value: true,
        config: {
          searchedFieldName: 'label',
          type: 'base',
        },
      };
      return [...suggestions, suggestion];
    }, []);
  }

  static getCustomerColumnSuggestions(rows: TradeCard[]): MultipleSearchSuggestion[] {
    const uniqueSuggestions: KeyValue<MultipleSearchSuggestion> = {};

    rows.forEach((row) => {
      row.customers?.forEach(({ user }) => {
        const customerId = user.id;

        if (!uniqueSuggestions[customerId]) {
          const baseUser = new BaseUser(user);
          uniqueSuggestions[customerId] = this.createSuggestion(
            customerId,
            `${baseUser.shortName}, ${user.company.name}`
          );
        }
      });
    });

    return Object.values(uniqueSuggestions);
  }

  static getSupplierColumnSuggestions(rows: SupplierTradeCard[]): MultipleSearchSuggestion[] {
    const uniqueSuggestions: KeyValue<MultipleSearchSuggestion> = {};

    rows.forEach((row) => {
      row.providers?.forEach(({ user }) => {
        const customerId = user.id;

        if (!uniqueSuggestions[customerId]) {
          const baseUser = new BaseUser(user);
          uniqueSuggestions[customerId] = this.createSuggestion(
            customerId,
            `${baseUser.shortName}, ${user.company.name}`
          );
        }
      });
    });

    return Object.values(uniqueSuggestions);
  }

  static getColumnSuggestionsFromLabelsConst(labels: KeyValue<string>): MultiSelectListItem[] {
    return Object.keys(labels).reduce((suggestions: MultiSelectListItem[], item: string) => {
      const label = labels[item];
      const suggestion: MultiSelectListItem = {
        id: item,
        name: label,
        checked: true,
      };
      return [...suggestions, suggestion];
    }, []);
  }

  static getSliderColumnValues(rows: TradeCard[], columnId: string): SliderValue {
    const getSliderValue = (row: TradeCard) =>
      columnId === TradeColumnsKeys.TARIFF ? row.tariff?.payment || 0 : row[columnId];

    const [adjustedFloor, adjustedCeil] = rows
      .reduce(
        (acc, row) => {
          const value = getSliderValue(row);
          acc[0] = isFinite(value) ? Math.min(acc[0], value) : acc[0];
          acc[1] = isFinite(value) ? Math.max(acc[1], value) : acc[1];
          return acc;
        },
        [Infinity, -Infinity]
      )
      .map((value) => (isFinite(value) ? value : 0));

    return {
      min: adjustedFloor,
      max: adjustedCeil,
    };
  }

  static getTradeResultsColumnValues(rows: SupplierTradeCard[]): SliderValue {
    const getSliderValue = (row: SupplierTradeCard) => {
      const place = row.number_place_in_ranking;

      return place < 1 ? 0 : place;
    };

    const floor = Math.min(...rows.map((row) => getSliderValue(row)));
    const ceil = Math.max(...rows.map((row) => getSliderValue(row)));

    return {
      min: floor,
      max: ceil,
    };
  }

  static getUserColumnSuggestions(rows: TradeCard[], columnId: string): MultipleSearchSuggestion[] {
    const uniqueSuggestions: KeyValue<MultipleSearchSuggestion> = {};

    rows.forEach((row) => {
      if (!row[columnId]) {
        return;
      }
      const user = new BaseUser(row[columnId]);

      if (!uniqueSuggestions[user.id]) {
        uniqueSuggestions[user.id] = this.createSuggestion(user.id, user.shortName);
      }
    });

    return Object.values(uniqueSuggestions);
  }

  static getCustomerCompanyColumnSuggestions(rows: SupplierTradeCard[]): MultipleSearchSuggestion[] {
    if (!rows.length) {
      return;
    }

    const uniqueSuggestions: KeyValue<MultipleSearchSuggestion> = {};

    rows.forEach((row) => {
      const ownerTradeCompany = row.owner_trade.company;
      const name = ownerTradeCompany.name
        ? ownerTradeCompany.name
        : ownerTradeCompany.organization_type + ' ' + ownerTradeCompany.organization_name;
      const nameAndItn = row.trade.owner.company.name_and_itn;
      const itn = ownerTradeCompany.itn ?? '-';

      uniqueSuggestions[ownerTradeCompany.id] = this.createSuggestion(nameAndItn, `${name}, ИНН ${itn}`);
    });

    return Object.values(uniqueSuggestions);
  }

  static getCustomerNames(rows: SupplierTradeCard[]): MultipleSearchSuggestion[] {
    if (!rows.length) {
      return;
    }

    const uniqueSuggestions: KeyValue<MultipleSearchSuggestion> = {};

    rows.forEach((row) => {
      const owner = new BaseUser(row.owner_trade);

      uniqueSuggestions[row.owner.full_name] = this.createSuggestion(row.owner.full_name, owner.shortName);
    });

    return Object.values(uniqueSuggestions);
  }

  static supplierTradeCardMapper(responseTrade: SupplierTradeResponse[]): SupplierTradeCard[] {
    return responseTrade.map((item) => {
      const { trade } = item;

      const resp: SupplierTradeCard = {
        ...item,
        ...trade,
        id: item.id,
        id_trade: trade.id,
        owner_trade: trade.owner,
        date_registration_payer: trade.payer.created_at,
        company: item.owner.company,
        date_state_registration: item.providers[0].user.company.date_state_registration,
        status: item.status,
        status_trade: trade.status,
        lots: [],
        trade,
        temp_data: null,
      };

      return resp;
    });
  }

  static getDateFilterValue(
    paramsList: FlaskQueryFilter[],
    columnId: TradeColumnsKeys,
    column: Column<TradesRequestConfig>
  ): any {
    const columnFilter = column.filterConfig.filter;
    const filter = paramsList.find((item) => TradesHelper.getColumnNameByColumnData(column, item) === columnId);

    if (!filter) return;

    if (columnFilter.length === 2) {
      const ge = columnFilter.find((f) => f.op === 'ge');
      const lt = columnFilter.find((f) => f.op === 'lt');

      if (!ge || !lt) {
        return;
      }

      return [ge.val, moment(lt.val).subtract(1, 'day')];
    }

    const { op, val } = filter;
    if (op === 'ge') {
      return [val, ''];
    } else if (op === 'le') {
      return ['', val];
    }
    return val;
  }

  static getDateWithNameFilterValue(
    paramsList: FlaskQueryFilter[],
    columnId: TradeColumnsKeys,
    column: Column<TradesRequestConfig>
  ): any {
    const filterWithName = paramsList.filter(
      (item) => TradesHelper.getColumnNameByColumnData(column, item) === columnId
    );
    if (filterWithName.length < 1) return;

    const { op, val } = filterWithName[0];
    const nameIds = filterWithName[1]?.val || [];
    if (op === 'ge') {
      return [val, '', nameIds];
    } else if (op === 'le') {
      return ['', val, nameIds];
    }
    return [...val, nameIds];
  }

  private static createSuggestion(id: number | string, label: string): MultipleSearchSuggestion {
    return { id, label, value: true };
  }

  private static getObjectWithoutProps<T extends Object, D extends string | number | symbol>(
    obj: T,
    propNames: Array<string>
  ): Omit<T, D> {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (!propNames.includes(key)) {
        newObj[key] = obj[key];
      }
    });

    return newObj as Omit<T, D>;
  }
}
