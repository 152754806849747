<div class="dc-slider">
  <form [formGroup]="form" class="form-range">
    <div class="input-wrapper">
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" class="ml-1">
        <input
          type="text"
          formControlName="inputMinValue"
          digitOnly
          appBlockCopyPaste
          appDragDrop
          [allowNegatives]="true"
          [decimal]="true"
          [min]="1"
          [max]="maxRange"
          (keypress)="isNumberKey($event)"
          (paste)="preventPaste('minValue')"
          [readonly]="readonly"
          (focus)="controlFocus($event, 'minValue')"
          (blur)="controlBlur($event, 'minValue')"
          appTestLocator="range-slider__min-value-input"
        />
      </dc-form-field-wrapper>
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" class="ml-1">
        <input
          type="text"
          formControlName="inputMaxValue"
          digitOnly
          appBlockCopyPaste
          appDragDrop
          [allowNegatives]="true"
          [decimal]="true"
          [min]="1"
          [max]="maxRange"
          (keypress)="isNumberKey($event)"
          (paste)="preventPaste('maxValue')"
          [readonly]="readonly"
          (focus)="controlFocus($event, 'maxValue')"
          (blur)="controlBlur($event, 'maxValue')"
          appTestLocator="range-slider__max-value-input"
        />
      </dc-form-field-wrapper>
    </div>
  </form>
</div>

<div *ngIf="hasError" class="error-message">
  Оба поля должны быть заполнены, и мин уровень не должен превышать макс уровень.
</div>
