<div class="dc-slider">
  <form [formGroup]="form" class="form-range">
    <div class="input-wrapper">
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" class="ml-1">
        <input
          type="text"
          formControlName="inputMinValue"
          appBlockCopyPaste
          appDragDrop
          digitOnly
          [allowNegatives]="true"
          [decimal]="true"
          [min]="options.floor"
          [max]="options.ceil"
          (keypress)="isNumberKey($event)"
          [readonly]="readonly"
          (focus)="controlFocus($event, 'minValue')"
          (blur)="controlBlur($event, 'minValue')"
          appTestLocator="range-slider__min-value-input"
        />
      </dc-form-field-wrapper>
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" class="ml-1">
        <input
          type="text"
          formControlName="inputMaxValue"
          digitOnly
          appBlockCopyPaste
          appDragDrop
          [allowNegatives]="true"
          [decimal]="true"
          [min]="options.floor"
          [max]="options.ceil"
          (keypress)="isNumberKey($event)"
          [readonly]="readonly"
          (focus)="controlFocus($event, 'maxValue')"
          (blur)="controlBlur($event, 'maxValue')"
          appTestLocator="range-slider__max-value-input"
        />
      </dc-form-field-wrapper>
    </div>

    <div class="ngx-slider-wrapper">
      <div
        class="ngx-slider-range ngx-slider-range--from"
        [title]="minRange"
        appTestLocator="range-slider__min-range"
      ></div>
      <ngx-slider formControlName="sliderValue" [options]="options"></ngx-slider>
      <div
        class="ngx-slider-range ngx-slider-range--to"
        [title]="maxRange"
        appTestLocator="range-slider__max-range"
      ></div>
    </div>
  </form>
</div>

<div *ngIf="hasError" class="error-message">
  Оба поля должны быть заполнены и мин уровень не должен превышать макс уровень
</div>
