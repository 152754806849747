<div
  class="select-date"
  id="select-date"
  [style.top.px]="position.y"
  [style.left.px]="position.x"
  [style.width.px]="size.width"
  [style.height.px]="size.height"
  [style.max-height.px]="size.height"
  (clickOutside)="onClickedOutside($event)"
>
  <div class="select-date-list" [style.max-height.px]="size.height - marginWindow" #yearsList>
    <ng-container *ngFor="let item of context">
      <div class="select-date-list-name" (click)="selectValue(item)">
        {{ item }}{{ type == typeSelect.WEEK ? ' неделя' : '' }}
      </div>
    </ng-container>
  </div>
</div>
