<div class="text-center">
  <div class="d-flex justify-content-between mb-4 range-wrapper">
    <div class="mr-1 range-number-label" *ngIf="label">{{ label }}</div>
    <div class="range-wrapper">
      <input
        type="text"
        name="from"
        class="kt-input kt-small2-input"
        [(ngModel)]="localValue.from"
        #from="ngModel"
        placeholder="От"
        onlyInteger
        ngDefaultControl
      />
      <input
        type="text"
        name="to"
        onlyInteger
        class="kt-input kt-small2-input"
        [(ngModel)]="localValue.to"
        #to="ngModel"
        placeholder="До"
        ngDefaultControl
      />
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-dc-primary" [disabled]="!from.valid || !to.valid" (click)="apply()">Фильтровать</button>
  </div>
</div>
