export enum STATE {
  IMPORTANT_IN_TIME = 'important_in_time',
  IMPORTANT = 'important',
  NOT_IMPORTANT = 'not_important',
  UNDER_CONTROL = 'under_control',
  EXECUTED = 'executed',
  CANCELLED = 'cancelled',
  DELETED = 'deleted',
}

export const FINISH_STATE = [STATE.CANCELLED, STATE.EXECUTED];

export const LIST_STATE = [
  {
    color: '#f88e86',
    text: 'Важно в срок',
    key: STATE.IMPORTANT_IN_TIME,
  },
  {
    color: '#f2ec6f',
    text: 'Важно, но срок можно перенести',
    key: STATE.IMPORTANT,
  },
  {
    color: '#66b5ef',
    text: 'Низкая важность, выполнить по возможности',
    key: STATE.NOT_IMPORTANT,
  },
  {
    color: '#323232',
    text: 'Держать на контроле',
    key: STATE.UNDER_CONTROL,
  },
  {
    disable_create: true,
    color: '#b3de82',
    text: 'Выполнено',
    key: STATE.EXECUTED,
  },
  {
    disable_create: true,
    color: '#c1c1c1',
    text: 'Отменено',
    key: STATE.CANCELLED,
  },
];

export const LIST_REPEAT_SELECT = [
  {
    name: 'Не повторять',
    value: 'no_repeat',
  },
  {
    name: 'По будням',
    value: 'on_weekdays',
  },
  {
    name: 'Каждый день',
    value: 'everyday',
  },
  {
    name: 'Каждую неделю',
    value: 'every_week',
  },
  {
    name: 'Каждый месяц',
    value: 'every_month',
  },
  {
    name: 'Каждый год',
    value: 'yearly',
  },
  {
    name: 'По дням недели:',
    value: 'by_days_of_the_week',
  },
];

export const LIST_REPEAT_DAY_SELECT = [
  {
    name: 'Пн',
    value: 1,
  },
  {
    name: 'Вт',
    value: 2,
  },
  {
    name: 'Ср',
    value: 3,
  },
  {
    name: 'Чт',
    value: 4,
  },
  {
    name: 'Пт',
    value: 5,
  },
  {
    name: 'Сб',
    fontWeight: 'bold',
    value: 6,
  },
  {
    name: 'Вс',
    fontWeight: 'bold',
    value: 7,
  },
];

export interface StatusModel {
  color: string;
  text: string;
  key: STATE;
}

export interface EventModel {
  created_at: string;
  description: string;
  end_date: string;
  end_time: string;
  flags: EventFlagModel;
  id: number;
  last_edited_at: string;
  owner: EventOwnerModel;
  extra?: {
    days_of_the_week: [];
    repeat_type?: string;
    repeat_id?: number;
    start_date_first_event?: string;
  };
  owner_id: number;
  start_date: string;
  start_time: string;
  status: STATE;
  title: string;
  type: string;
  repeat_type: string;
  days_of_the_week: number[];
}

export interface EventFlagModel {
  [flagName: string]: boolean;
}

export interface EventOwnerModel {
  id: number;
}

export interface EventFilterModel {
  startDate: string;
  endDate: string;
  state?: STATE[];
}

export const TITLE_FORM_EVENT = {
  title: 'Название',
  status: 'Статус',
  description: 'Описание',
  start_date: 'Дата начала сообытия',
  start_time: 'Время начала сообытия',
  end_date: 'Дата окончания события',
  end_time: 'Время окончания события',
  repeat_type: 'Повторение',
  days_of_the_week: 'Дни недели повторения',
};

export interface Week {
  day: string;
  number: number;
}

export const WEEK: Week[] = [
  {
    day: '',
    number: 0,
  },
  {
    day: 'Пн',
    number: 1,
  },
  {
    day: 'Вт',
    number: 2,
  },
  {
    day: 'Ср',
    number: 3,
  },
  {
    day: 'Чт',
    number: 4,
  },
  {
    day: 'Пт',
    number: 5,
  },
  {
    day: 'Сб',
    number: 6,
  },
  {
    day: 'Вс',
    number: 7,
  },
];
