// ROLES (http://wiki.dircont.com/pages/viewpage.action?pageId=1277979)
// АТП [superuser]
// Бухгалтер [accountant]
// Эксперт [expert]
// Агент [partner]
// АП [admin_of_user]
// АН[admin_of_direction]
// Оператор [operator]

import { HELP_HINTS } from './help-hints.constants';

export interface menuItem {
  link: string;
  label: string;
  short?: string;
  icon?: string[];
  sprite?: string;
  sprite_size?: number[];
  hintId?: number;
  sub_items?: menuItem[];
}

export interface menu<T> {
  [key: string]: {
    [key: string]: T;
  };
}

const FILE_MANAGER_MENU_ITEMS: menuItem[] = [
  {
    link: '/file-manager',
    label: 'Файлообменник',
    icon: ['far', 'copy'],
    sprite: 'file_manager',
    sprite_size: [16, 16],
    hintId: HELP_HINTS.FILE_MANAGER.SIDEBAR_TOOLTIP,
  },
  // TODO: Старый файло обменник оставлен для теста после окнчания тестирования вычистить!
  // {
  //   link: '/files',
  //   label: 'Файлообменник',
  //   icon: ['far', 'copy'],
  //   sprite: 'file_manager',
  //   sprite_size: [16, 16],
  //   hintId: HELP_HINTS.FILE_MANAGER.SIDEBAR_TOOLTIP,
  // },
];

const FAQ_MENU_ITEMS: menuItem[] = [
  {
    link: '/FAQ',
    label: 'ЧаВо',
    icon: ['far', 'question-circle'],
    sprite: 'question_circle',
    sprite_size: [16, 16],
    hintId: HELP_HINTS.FAQ.SIDEBAR_TOOLTIP,
  },
];

const FAQ_EDIT_MENU_ITEMS: menuItem[] = [
  ...FAQ_MENU_ITEMS,
  {
    link: '/FAQ/edit',
    label: 'ЧаВо и примечания (Р.)',
    icon: ['far', 'edit'],
    sprite: 'faq_edit',
    sprite_size: [18, 17],
    hintId: HELP_HINTS.FAQ_EDIT.SIDEBAR_TOOLTIP,
  },
];

const MY_COMPETENCES_MENU_ITEMS: menuItem[] = [
  {
    link: '/map/my-competences',
    label: 'Мои компетенции',
    icon: ['fas', 'bullseye'],
    sprite: 'my_competences',
    sprite_size: [18, 16],
  },
];

const EXPERT_COMPETENCES_MENU_ITEMS: menuItem[] = [
  {
    link: '/map/expert-competences',
    label: 'Мои компетенции',
    icon: ['fas', 'bullseye'],
    sprite: 'my_competences',
    sprite_size: [18, 16],
  },
];

const MAP_EDITOR_MENU_ITEMS: menuItem[] = [
  {
    link: '/map/editor',
    label: 'Карта компетенций (Р.)',
    icon: ['fas', 'bullseye'],
    sprite: 'map',
    sprite_size: [18, 16],
  },
];

const TRADES_MENU_ITEMS: menuItem[] = [
  {
    link: '/trades/customer',
    label: 'Торги заказчика',
    icon: ['fas', 'bullseye'],
    sprite: 'trade_customer',
    sprite_size: [16, 16],
  },
  {
    link: '/trades/supplier',
    label: 'Торги поставщика',
    icon: ['fas', 'bullseye'],
    sprite: 'trade_supplier',
    sprite_size: [16, 16],
  },
];

const PROFILE_MENU_ITEMS: menuItem[] = [
  {
    link: '/profile',
    label: 'Личные данные',
    icon: ['far', 'user-circle'],
    sprite: 'profile_left',
    sprite_size: [16, 16],
  },
];

const EMPLOYEE_MENU_ITEMS: menuItem[] = [
  {
    link: '/employees',
    label: 'Сотрудники',
    icon: ['fas', 'sitemap'],
    sprite: 'sitemap',
    sprite_size: [18, 16],
    hintId: HELP_HINTS.EMPLOYEE.SIDEBAR_TOOLTIP,
  },
];

export const MENU_ITEMS = {
  unknown: {},
  // Меню для демонстрации макетов
  admin_of_direction: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      ...EMPLOYEE_MENU_ITEMS,
      ...MY_COMPETENCES_MENU_ITEMS,
      {
        link: '/tariffs-payments/tariffs-selectable',
        label: 'Тарифы',
        icon: ['fas', 'bullseye'],
        sprite: 'money-bill',
        sprite_size: [18, 16],
      },
      {
        link: '/tariffs-payments/registry',
        label: 'Кошелек',
        icon: ['fas', 'bullseye'],
        sprite: 'coins',
        sprite_size: [16, 16],
      },
      ...TRADES_MENU_ITEMS,
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_MENU_ITEMS,
    ],
  },
  operator: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      ...MY_COMPETENCES_MENU_ITEMS,
      ...TRADES_MENU_ITEMS,
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_MENU_ITEMS,
    ],
  },
  superuser: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      ...EMPLOYEE_MENU_ITEMS,
      ...MAP_EDITOR_MENU_ITEMS,
      {
        link: '/tariffs-payments/tariffs-editor',
        label: 'Тарифы (Р.)',
        icon: ['fas', 'bullseye'],
        sprite: 'money-bill',
        sprite_size: [18, 16],
      },
      {
        link: '/tariffs-payments/registry',
        label: 'Реестр оплат',
        icon: ['fas', 'bullseye'],
        sprite: 'payment_registry',
        sprite_size: [16, 16],
      },
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_EDIT_MENU_ITEMS,
    ],
  },
  expert: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      ...MAP_EDITOR_MENU_ITEMS,
      ...EXPERT_COMPETENCES_MENU_ITEMS,
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_EDIT_MENU_ITEMS,
    ],
  },
  accountant: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      {
        link: '/tariffs-payments/tariffs',
        label: 'Тарифы (Р.)',
        icon: ['far', 'copy'],
        sprite: 'money-bill',
        sprite_size: [18, 16],
      },
      {
        link: '/tariffs-payments/registry',
        label: 'Реестр оплат',
        icon: ['fas', 'bullseye'],
        sprite: 'payment_registry',
        sprite_size: [16, 16],
      },
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_MENU_ITEMS,
    ],
  },
  partner: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      ...EXPERT_COMPETENCES_MENU_ITEMS,
      {
        link: '/tariffs-payments/tariffs-selectable-tabs',
        label: 'Тарифы',
        icon: ['fas', 'bullseye'],
        sprite: 'money-bill',
        sprite_size: [18, 16],
      },
      {
        link: '/tariffs-payments/registry',
        label: 'Кошелек',
        icon: ['fas', 'bullseye'],
        sprite: 'coins',
        sprite_size: [16, 16],
      },
      {
        link: '/tso',
        label: 'Функции ОТП',
        icon: ['fas', 'bullseye'],
        sprite: 'life_ring',
        sprite_size: [16, 16],
      },
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_MENU_ITEMS,
    ],
  },
  admin_of_user: {
    tuning: [
      ...PROFILE_MENU_ITEMS,
      ...EMPLOYEE_MENU_ITEMS,
      ...MY_COMPETENCES_MENU_ITEMS,
      {
        link: '/tariffs-payments/tariffs-selectable',
        label: 'Тарифы',
        icon: ['fas', 'bullseye'],
        sprite: 'money-bill',
        sprite_size: [18, 16],
      },
      {
        link: '/tariffs-payments/registry',
        label: 'Кошелек',
        icon: ['fas', 'bullseye'],
        sprite: 'coins',
        sprite_size: [16, 16],
      },
      ...TRADES_MENU_ITEMS,
      ...FILE_MANAGER_MENU_ITEMS,
      ...FAQ_MENU_ITEMS,
    ],
  },
};
