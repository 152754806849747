import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { MultipleSearchSuggestion, MultipleSelectConfig } from '@app/shared/models/multiple-search.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-radio-filter-livesearch',
  templateUrl: './radio-filter-livesearch.component.html',
  styleUrls: ['./radio-filter-livesearch.component.scss'],
  providers: [FilterPipe, DestroyService],
})
export class RadioFilterLivesearchComponent implements OnInit, OnDestroy {
  searchValue = '';
  private config: MultipleSelectConfig;
  private searchControl = new FormControl('', []);
  private _list: MultipleSearchSuggestion[] = [];
  form: FormGroup;
  currentTariffID: number;

  @Input() template: TemplateRef<any>;
  @Input() placeholder: string = '';
  @Input() isNumber = true;
  @Input() disabled: boolean;

  @Output() searchEvent = new EventEmitter<string>();
  @Output() selectEvent = new EventEmitter<MultipleSearchSuggestion[]>();
  @Output() onDisabledButton = new EventEmitter<boolean>();

  @Input() set list(list: MultipleSearchSuggestion[]) {
    if (!list.length) {
      return;
    }

    this.searchControl.valueChanges.pipe(startWith(''), takeUntil(this.destroy$)).subscribe((searchValue) => {
      this.onDisabledButton.emit(!searchValue);
      if (!searchValue) {
        this._list = list;
      } else {
        this._list = this.filterList(list, searchValue.toLowerCase());
        this.searchEvent.emit(searchValue);
      }
    });

    this._list = list;
    this.config = list[0].config;
  }

  constructor(
    private filterPipe: FilterPipe<MultipleSearchSuggestion>,
    private destroy$: DestroyService,
    private fb: FormBuilder
  ) {}

  get list(): MultipleSearchSuggestion[] {
    return this._list;
  }

  get searchedFieldName(): string {
    return this.config?.searchedFieldName ? this.config.searchedFieldName : 'label';
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      action: '',
    });

    this.form
      .get('action')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((item) => {
        this.list.map((tariff) => (tariff.value = false));
        item.value = !item.value;
        this.currentTariffID = item.id;
        this.onDisabledButton.emit(!item);

        this.selectEvent.emit(this.list);
      });
  }

  ngOnDestroy(): void {
    this.searchEvent.next('');
  }

  private filterList(list: MultipleSearchSuggestion[], searchValue: string): MultipleSearchSuggestion[] {
    const lowerCaseSearchValue = searchValue.toLowerCase();

    return list.filter(({ data, label }) => {
      const dataString = data && (data[1] as string)?.toLowerCase();
      const labelString = label && label.toLowerCase();

      return (
        (dataString && dataString.includes(lowerCaseSearchValue)) ||
        (labelString && labelString.includes(lowerCaseSearchValue))
      );
    });
  }
}
