export const DATE_SHORT_FORMAT = 'MM.YYYY';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';

export const SHORT_DATE_FORMAT = 'DD.MM.YY';
export const DATE_TIME_SEPARATOR = ' - ';

export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_TIME_FORMAT = 'HH:mm:ss';

export const TABLE_DATE_FORMAT = 'DD MMMM YYYY года, h:mm';

export const BACKEND_TIMESTAMP_FORMAT = 'X';

export const TIMEZONES = [
  {
    name: '(GMT -12:00) Eniwetok, Kwajalein',
    value: -720,
  },
  {
    name: '(GMT -11:00) Midway Island, Samoa',
    value: -660,
  },
  {
    name: '(GMT -10:00) Hawaii',
    value: -540,
  },
  {
    name: '(GMT -8:00) Pacific Time (US &amp; Canada)',
    value: -480,
  },
  {
    name: '(GMT -7:00) Mountain Time (US &amp; Canada)',
    value: -420,
  },
  {
    name: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City',
    value: -360,
  },
  {
    name: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima',
    value: -300,
  },
  {
    name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    value: -240,
  },
  {
    name: '(GMT -3:30) Newfoundland',
    value: -210,
  },
  {
    name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    value: -180,
  },
  {
    name: '(GMT -2:00) Mid-Atlantic',
    value: -120,
  },
  {
    name: '(GMT -1:00 hour) Azores, Cape Verde Islands',
    value: -60,
  },
  {
    name: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    value: 0,
  },
  {
    name: '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris',
    value: 60,
  },
  {
    name: '(GMT +2:00) Kaliningrad, South Africa',
    value: 120,
  },
  {
    name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: 180,
  },
  {
    name: '(GMT +3:30) Tehran',
    value: 210,
  },
  {
    name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    value: 240,
  },
  {
    name: '(GMT +4:30) Kabul',
    value: 270,
  },
  {
    name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: 300,
  },
  {
    name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    value: 330,
  },
  {
    name: '(GMT +5:45) Kathmandu',
    value: 345,
  },
  {
    name: '(GMT +6:00) Almaty, Dhaka, Colombo',
    value: 360,
  },
  {
    name: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    value: 420,
  },
  {
    name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    value: 480,
  },
  {
    name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: 540,
  },
  {
    name: '(GMT +9:30) Adelaide, Darwin',
    value: 570,
  },
  {
    name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    value: 600,
  },
  {
    name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    value: 660,
  },
  {
    name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: 720,
  },
];
