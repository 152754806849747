import { Column } from '@app/shared/models/table';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { EmployeeTreeFilter } from '@app/+employees/shared';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { OPERATOR } from '@app/shared/enums/flask-operators';
import { RolesEnum } from '@app/shared/constants/roles.constants';

export enum EmployeeRightTypes {
  YES = 'true',
  NO = 'false',
}

export enum EmployeeTrades {
  YES = 'true',
  NO = 'false',
}

export enum EmployeeBwg {
  UP = 'up',
  DOWN = 'down',
}

export enum EmployeeColumnsKeys {
  ACTION = 'action',
  SUBORDINATES = 'subordinates_count',
  ID = 'id',
  EMPLOYEE_CARD = 'employee_card',
  ROLE = 'type',
  COMPETENCES = 'competence',
  STATUS = 'status',
  RIGHT_TO_PAYMENT = 'right_to_payment',
  RIGHT_TO_POSSESSION = 'right_to_possession',
  SELF_PAYER = 'self_payer',
  OTP = 'otp',
  TARIFF_TRADE = 'tariff_trade',
}

export enum EmployeeFilterKey {
  SUBORDINATES = 'level',
  ID = 'id',
  EMPLOYEE_CARD = 'second_name',
  ROLE = 'type',
  STATUS = 'status',
  FLAGS = 'flags',
}

export enum Right {
  RIGHT_TO_PAYMENT = 'is_can_be_payer',
  RIGHT_TO_POSSESSION = 'is_right_of_possession',
}

export const EMPLOYEE_COLUMNS: Column<EmployeeTreeFilter>[] = [
  {
    id: EmployeeColumnsKeys.ACTION,
    name: '',
    hiddenPrinting: true,
    isHiddenSettingForm: true,
    isFilterActive: true,
    width: 35,
  },
  {
    id: EmployeeColumnsKeys.SUBORDINATES,
    name: 'Структура<br/>сотрудников',
    filterKey: EmployeeFilterKey.SUBORDINATES,
    label: 'Показать уровни сотрудников',
    isEnabledFilter: true,
    isEnabledSorting: true,
    data: {
      isSticky: true,
      indent: 0,
    },
    width: 137,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.SUBORDINATES,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.ID,
    name: 'ID',
    filterKey: EmployeeFilterKey.ID,
    label: 'Поиск по ID сотрудника',
    searchPlaceholder: 'Начните вводить ID сотрудника',
    isEnabledSearch: true,
    data: {
      isSticky: true,
      indent: 138,
    },
    width: 69,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.ID,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.EMPLOYEE_CARD,
    name: 'Сотрудники',
    filterKey: EmployeeFilterKey.EMPLOYEE_CARD,
    label: 'Поиск по фамилии сотрудника',
    searchPlaceholder: 'Начните вводить фамилию сотрудника',
    isEnabledSearch: true,
    data: {
      isSticky: true,
      indent: 208,
    },
    width: 210,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.EMPLOYEE_CARD,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.ROLE,
    name: 'Роль',
    filterKey: EmployeeFilterKey.ROLE,
    label: 'Показать роли',
    isEnabledFilter: true,
    isEnabledSorting: true,
    data: {
      isSticky: false,
      indent: 260,
    },
    width: 161,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.ROLE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.COMPETENCES,
    name: 'Компетенции',
    label: 'Показать',
    searchPlaceholder: 'Начните вводить название товара или услуги',
    isEnabledFilter: true,
    isEnabledSorting: true,
    width: 151,
    filterConfig: {
      section_ids: null,
      section_title: null,
    },
  },
  {
    id: EmployeeColumnsKeys.STATUS,
    name: 'Статус',
    filterKey: EmployeeFilterKey.STATUS,
    label: 'Показать',
    isEnabledFilter: true,
    isEnabledSorting: true,
    width: 111,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.STATUS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.RIGHT_TO_PAYMENT,
    name: 'Право оплаты<br/>тарифа',
    filterKey: EmployeeFilterKey.FLAGS,
    filteredValue: Right.RIGHT_TO_PAYMENT,
    label: 'Показать',
    isEnabledFilter: true,
    isEnabledSorting: true,
    width: 161,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.FLAGS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.RIGHT_TO_POSSESSION,
    name: 'Право владения<br/>торгами',
    filterKey: EmployeeFilterKey.FLAGS,
    filteredValue: Right.RIGHT_TO_POSSESSION,
    label: 'Показать',
    isEnabledFilter: true,
    isEnabledSorting: true,
    width: 175,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.FLAGS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.TARIFF_TRADE,
    name: 'Проведенные торги/<br/>Начисленный тариф',
    label: 'Показать торги за период',
    isEnabledFilter: true,
    isEnabledSorting: true,
    searchPlaceholder: '',
    width: 204,
    filterConfig: {
      trades_from: null,
      trades_to: null,
    },
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig?.filter,
    },
  };
});

export const EMPLOYEE_SUPERUSER_COLUMNS: Column<EmployeeTreeFilter>[] = [
  {
    id: EmployeeColumnsKeys.ID,
    name: 'ID',
    filterKey: EmployeeFilterKey.ID,
    label: 'Поиск по ID сотрудника',
    searchPlaceholder: 'Начните вводить ID сотрудника',
    isEnabledSearch: true,
    data: {
      isSticky: true,
      indent: 0,
    },
    width: 69,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.ID,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.EMPLOYEE_CARD,
    name: 'Сотрудники',
    filterKey: EmployeeFilterKey.EMPLOYEE_CARD,
    label: 'Поиск по фамилии сотрудника',
    searchPlaceholder: 'Начните вводить фамилию сотрудника',
    isEnabledSearch: true,
    data: {
      isSticky: true,
      indent: 70,
    },
    width: 201,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.EMPLOYEE_CARD,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.ROLE,
    name: 'Роль',
    filterKey: EmployeeFilterKey.ROLE,
    label: 'Показать роли',
    isEnabledFilter: true,
    isEnabledSorting: true,
    data: {
      isSticky: false,
      indent: 260,
    },
    width: 161,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.ROLE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.COMPETENCES,
    name: 'Компетенции',
    label: 'Показать',
    searchPlaceholder: 'Начните вводить название товара или услуги',
    isEnabledFilter: true,
    isEnabledSorting: true,
    width: 151,
    filterConfig: {
      section_ids: null,
      section_title: null,
    },
  },
  {
    id: EmployeeColumnsKeys.STATUS,
    name: 'Статус',
    filterKey: EmployeeFilterKey.STATUS,
    label: 'Показать',
    isEnabledFilter: true,
    isEnabledSorting: true,
    width: 111,
    filterConfig: {
      filter: {
        name: EmployeeFilterKey.STATUS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: EmployeeColumnsKeys.OTP,
    name: 'ОТП',
    isEnabledFilter: false,
    isEnabledSorting: false,
    width: 50,
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig?.filter,
    },
  };
});

export const EMPLOYEE_TRADES: MultiSelectListItem[] = [
  {
    id: EmployeeTrades.YES,
    name: 'Да',
    icon: ['fas', 'circle'],
    classes: ['status-green'],
    checked: false,
  },
  {
    id: EmployeeTrades.NO,
    name: 'Нет',
    icon: ['fas', 'circle'],
    classes: ['status-red'],
    checked: false,
  },
];

export const COMPANY = 'ПК';

export const EMPLOYEE_PAYMENT_TYPES: MultiSelectListItem[] = [
  {
    id: EmployeeRightTypes.YES,
    name: 'С правом оплаты тарифа',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: true,
  },
  {
    id: EmployeeRightTypes.NO,
    name: 'Без права оплаты тарифа',
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
];

export const EMPLOYEE_POSSESSION_TYPES: MultiSelectListItem[] = [
  {
    id: EmployeeRightTypes.YES,
    name: 'С правом владения торгами',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: true,
  },
  {
    id: EmployeeRightTypes.NO,
    name: 'Без права владения торгами',
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
];

export const EMPLOYEE_STATUSES: MultiSelectListItem[] = [
  {
    id: StatusesEnum.ACTIVE,
    name: 'Актив',
    icon: ['fas', 'circle'],
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: false,
  },
  {
    id: StatusesEnum.VIEW,
    name: 'Просмотр',
    icon: ['fas', 'circle'], // удалить, icon когда когда все будет переписано с <app-svg-icon>
    iconSvg: 'circle',
    classes: ['status-yellow'],
    checked: false,
  },
  {
    id: StatusesEnum.IN_ARCHIVE,
    name: 'Архив',
    icon: ['fas', 'circle'],
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
  {
    id: StatusesEnum.BLOCKED,
    name: 'Заблокирован',
    icon: ['fas', 'circle'],
    classes: ['status-red'],
    checked: false,
  },
];

export const AGENTS_PARAMS = {
  name: 'type',
  op: OPERATOR.IN,
  val: [RolesEnum.PARTNER],
};

export const EXPERTS_PARAMS = {
  name: 'type',
  op: OPERATOR.IN,
  val: [RolesEnum.EXPERT],
};

export const SUPERUSER_PARAMS = {
  name: 'type',
  op: OPERATOR.IN,
  val: [RolesEnum.SUPERUSER],
};
