<div *ngIf="closeButton" (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="user-modal">
  <div class="modal-header">
    <div class="modal-title" appTestLocator="user-card__title">{{ title }}</div>
  </div>
  <div class="modal-body" *ngIf="user$ | async as userData">
    <div
      class="user-card-row padding-l-26"
      appTestLocator="user-card__fullname"
      [disableTooltip]="maxLength(userData?.fullName, 30)"
      [ngbTooltip]="userData?.fullName"
    >
      <span> {{ userData?.fullName }}</span>
    </div>

    <div
      class="user-card-row"
      appTestLocator="user-card__company"
      [disableTooltip]="maxLength(getCompanyName(userData), 27)"
      [ngbTooltip]="getCompanyName(userData)"
    >
      <app-status-viewer class="padding-r-8" [status]="userData.status"></app-status-viewer>
      <span> {{ getCompanyName(userData) }}</span>
    </div>

    <div class="user-card-row padding-l-26" appTestLocator="user-card__type">
      {{ userData?.type | fullTitleRole }}
    </div>
    <div *ngFor="let phone of userData?.phones" class="user-card-row" appTestLocator="user-card__phone">
      <app-svg-icon name="phone" width="16"></app-svg-icon>
      {{ phone | textSeparator : '-' : 3 }}
    </div>
    <div *ngIf="!userData?.phones.length" class="user-card-row" appTestLocator="user-card__phone">
      <app-svg-icon name="phone" width="16"></app-svg-icon>
      <span>Телефон не задан!</span>
    </div>
    <div
      class="user-card-row"
      appTestLocator="user-card__email"
      [disableTooltip]="maxLength(userData?.email || '-', 27)"
      [ngbTooltip]="userData?.email"
    >
      <app-svg-icon name="email" width="16"></app-svg-icon>
      <span>{{ userData?.email || '-' }}</span>
    </div>
    <div class="user-card-row">
      <app-svg-icon name="comment" width="16"></app-svg-icon>
      <a
        [chatLink]="userData.id"
        appTestLocator="user-card__chat"
        (click)="cancel()"
        [class.disabled]="userData.id === userId"
        >Написать в чат</a
      >
    </div>
  </div>
</div>
