import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @HostListener('dragstart', ['$event']) blockDragStart(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('dragover', ['$event']) blockDragOver(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('drop', ['$event']) blockDrop(e: KeyboardEvent) {
    e.preventDefault();
  }
}
