<div class="mb-4">
  <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
    <dc-form-input [isShowClear]="true">
      <input
        formInput
        [onlyInteger]="!!isNumber"
        [(ngModel)]="searchValue"
        (ngModelChange)="searchEvent.next($event)"
        [placeholder]="placeholder"
        appTestLocator="radio-filter-livesearch__search"
      />
    </dc-form-input>
  </dc-form-field-wrapper>
</div>

<form [formGroup]="form" class="radio-filter-livesearch-text-list radio-brand-filter">
  <ng-container *ngIf="list?.length">
    <div
      *ngFor="let item of list | filter : searchValue : searchedFieldName"
      class="mb-1 radio-item"
      [class.disabled]="item.disabled || disabled"
    >
      <input
        type="radio"
        class="input-brand"
        id="radio-filter-livesearch{{ item.id }}"
        [value]="item"
        [checked]="currentTariffID === item.id"
        formControlName="action"
      />
      <ng-container *ngTemplateOutlet="template ? template : base; context: { $implicit: item }"></ng-container>
    </div>
  </ng-container>
  <div *ngIf="!list?.length" appTestLocator="radio-filter-livesearch__no-data">Нет значений</div>
</form>

<ng-template #base let-item>
  <label
    class="radio-brand-filter-label"
    [for]="'radio-filter-livesearch' + item.id"
    [ngbTooltip]="item.label"
    [disableTooltip]="item.label?.toString()?.length < 24"
    appTestLocator="'radio-filter-livesearch__item-{{ item.id }}"
  >
    <span class="mark-brand-radio"></span>
    <div class="text-overflow">
      <span class="radio-brand-filter-text" [innerHTML]="item.label | highlight : searchValue"></span>
    </div>
  </label>
</ng-template>
