import { InjectionToken } from '@angular/core';
import { ReasonEndTrade, TradeCardStatus } from '../types/trades.types';

export const MAX_TRADE_CARD_TITLE_LENGTH = 100;

export enum ReasonEndTradeEnum {
  PLANNED_COMPLETION = 'planned_completion',
  EARLY_COMPLETION = 'early_completion',
  EARLY_LACK_PROVIDERS = 'early_lack_providers',
}

export const DEFAULT_AUTO_PROLONGATION_MINUTES = 15;

export enum TradeCardStatuses {
  PROJECT = 'project',
  PRE_TRADE = 'pre_trade',
  TRADE = 'trade',
  ARCHIVE = 'archive',
  TEMPLATE = 'template',
}

export const TRADE_STATUS_LABELS = {
  [TradeCardStatuses.PROJECT]: 'Проект',
  [TradeCardStatuses.PRE_TRADE]: 'Предторги',
  [TradeCardStatuses.TRADE]: 'Торги',
  [TradeCardStatuses.ARCHIVE]: 'Архив',
  [TradeCardStatuses.TEMPLATE]: 'Шаблон',
};

export const SUPPLIER_TRADE_STATUS_LABELS = {
  [TradeCardStatuses.PRE_TRADE]: 'Предторги',
  [TradeCardStatuses.TRADE]: 'Торги',
  [TradeCardStatuses.ARCHIVE]: 'Архив',
};

export const TRADE_CANCEL_LABELS = {
  [ReasonEndTradeEnum.PLANNED_COMPLETION]: 'Плановое завершение',
  [ReasonEndTradeEnum.EARLY_COMPLETION]: 'Досрочно, по инициативе заказчика',
  [ReasonEndTradeEnum.EARLY_LACK_PROVIDERS]: 'Досрочно, в связи с отсутствием поставщиков',
};

export enum AccessRights {
  OWNER = 'owner',
  EDIT = 'edit',
  VIEW = 'view',
}

export interface TradeStatus {
  id: TradeCardStatus;
  name: string;
  checked?: boolean;
}

export interface TradeCancel {
  id: ReasonEndTrade;
  name: string;
  checked?: boolean;
}

export interface TradeCardDate {
  date: TradeDate;
  name: string;
}

export const TRADE_STATUSES: TradeStatus[] = [
  {
    id: TradeCardStatuses.PROJECT,
    name: 'Проект',
    checked: false,
  },
  {
    id: TradeCardStatuses.PRE_TRADE,
    name: 'Предторги',
    checked: false,
  },
  {
    id: TradeCardStatuses.TRADE,
    name: 'Торги',
    checked: false,
  },
  {
    id: TradeCardStatuses.ARCHIVE,
    name: 'Архив',
    checked: false,
  },
  {
    id: TradeCardStatuses.TEMPLATE,
    name: 'Шаблон',
    checked: false,
  },
];

export const REASON_AND_TRADES: TradeCancel[] = [
  {
    id: ReasonEndTradeEnum.PLANNED_COMPLETION,
    name: TRADE_CANCEL_LABELS[ReasonEndTradeEnum.PLANNED_COMPLETION],
    checked: false,
  },
  {
    id: ReasonEndTradeEnum.EARLY_COMPLETION,
    name: TRADE_CANCEL_LABELS[ReasonEndTradeEnum.EARLY_COMPLETION],
    checked: false,
  },
  {
    id: ReasonEndTradeEnum.EARLY_LACK_PROVIDERS,
    name: TRADE_CANCEL_LABELS[ReasonEndTradeEnum.EARLY_LACK_PROVIDERS],
    checked: false,
  },
];

export enum TradeDate {
  Create = 'date_create_trade',
  InvitationsSent = 'date_invitations_are_sent',
  Start = 'date_start_trade',
  End = 'date_end_trade',
}

export const TRADE_CARD_DATES: TradeCardDate[] = [
  {
    date: TradeDate.Create,
    name: 'Создания проекта КТ',
  },
  {
    date: TradeDate.InvitationsSent,
    name: 'Отправки приглашений',
  },
  {
    date: TradeDate.Start,
    name: 'Начала торгов',
  },
  {
    date: TradeDate.End,
    name: 'Завершения торгов',
  },
];

export const TRADE_ACCESS_RIGHTS_ICONS_COLLECTION: { [key in AccessRights]: string } = {
  [AccessRights.EDIT]: 'edit',
  [AccessRights.OWNER]: 'crown',
  [AccessRights.VIEW]: 'eye',
};

export const PROLONGATION_VARIANTS = [
  {
    name: 'Не продлять',
    value: 0,
  },
  {
    name: '15 минут',
    value: 15,
  },
  {
    name: '30 минут',
    value: 30,
  },
  {
    name: '45 минут',
    value: 45,
  },
  {
    name: '60 минут',
    value: 60,
  },
];

export enum TradeFilterOption {
  All = 'all',
  Active = 'active',
  Number = 'number',
}
