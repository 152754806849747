import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { DestroyService } from '@app/services/destroy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-range-input',
  templateUrl: './form-range-input.component.html',
  styleUrls: ['./form-range-input.component.scss'],
})
export class FormRangeInputComponent implements OnInit {
  private _readonly = false;
  hasError = false;
  private isProcess = false;
  private _minRange: number;
  private _maxRange: number;
  form: FormGroup = new FormGroup({
    inputMinValue: new FormControl(0),
    inputMaxValue: new FormControl(0),
  });

  @Output() changeRange = new EventEmitter();
  @Output() onInvalidForm = new EventEmitter();

  @Input() set minRange(minRange: number) {
    this._minRange = minRange;
  }

  get minRange(): number {
    return this._minRange;
  }

  @Input() set maxRange(maxRange: number) {
    this._maxRange = maxRange;
  }

  get maxRange(): number {
    return this._maxRange;
  }

  @Input() set setValueFrom(value: number) {
    const inputValue = value === null ? '' : value;
    this.inputMinControl.setValue(inputValue, { emitEvent: false });
  }

  @Input() set setValueTo(value: number) {
    const inputValue = value === null ? '' : value;
    this.inputMaxControl.setValue(inputValue, { emitEvent: false });
  }

  get inputMinControl(): AbstractControl {
    return this.form.get('inputMinValue');
  }

  get inputMaxControl(): AbstractControl {
    return this.form.get('inputMaxValue');
  }

  get readonly(): boolean {
    return this._readonly;
  }

  constructor(private destroy$: DestroyService) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const newMin = value.inputMinValue.toString().replace(/[^0-9]/g, '');
      const newMax = value.inputMaxValue.toString().replace(/[^0-9]/g, '');

      if (value.inputMinValue !== newMin) {
        this.form.patchValue({
          inputMinValue: newMin,
        });

        if (
          this.inputMinControl.value === '' ||
          +this.inputMinControl.value < this.minRange ||
          +this.inputMinControl.value > +this.inputMaxControl.value
        ) {
          this.hasError = true;
        }
      }

      if (value.inputMaxValue !== newMax) {
        this.form.patchValue({
          inputMaxValue: newMax,
        });

        if (
          this.inputMaxControl.value === '' ||
          +this.inputMaxControl.value < this.minRange ||
          +this.inputMinControl.value > +this.inputMaxControl.value
        ) {
          this.hasError = true;
        }
      }

      this.onInvalidForm.emit(
        (+value.inputMinValue < +this.minRange && value.inputMinValue !== '' && value.inputMaxValue !== '') ||
          value.inputMinValue === '' ||
          value.inputMaxValue === '' ||
          +value.inputMaxValue < this.minRange ||
          +value.inputMinValue > +value.inputMaxValue ||
          this.hasError
      );

      if (+value.inputMinValue <= +value.inputMaxValue) {
        this.hasError = false;
        this.onInvalidForm.emit(false);
      }

      this.changeRange.emit({
        min: parseFloat(value.inputMinValue) || '',
        max: parseFloat(value.inputMaxValue) || '',
      });
    });
  }

  controlFocus($event: FocusEvent = null, fieldName: string): void {
    this.isProcess = true;
    this.hasError = false;
    if (this._readonly) {
      return;
    }
  }

  controlBlur($event: FocusEvent = null, fieldName: string): void {
    this.hasError = false;
    this.isProcess = false;
    if (this._readonly) {
      return;
    }

    if (
      fieldName === 'minValue' &&
      (this.inputMinControl.value === '' || +this.inputMinControl.value < this.minRange)
    ) {
      this.form.patchValue({
        inputMinValue: this.minRange,
      });
      this.hasError = true;
    }

    if (
      fieldName === 'maxValue' &&
      (this.inputMaxControl.value === '' || +this.inputMaxControl.value < this.minRange)
    ) {
      this.form.patchValue({
        inputMaxValue: this.maxRange,
      });
      this.hasError = true;
    }

    if (+this.inputMaxControl.value < +this.inputMinControl.value) {
      this.hasError = true;
    }
  }

  isNumberKey(evt: KeyboardEvent): boolean {
    const charCode = evt.which ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  preventPaste(fieldName) {
    if (fieldName === 'minValue' && this.inputMinControl.value.length > 1) {
      this.form.patchValue({
        inputMinValue: this.inputMinControl.value.slice(0, -1),
      });
    }

    if (fieldName === 'maxValue' && this.inputMaxControl.value.length > 1) {
      this.form.patchValue({
        inputMaxValue: this.inputMaxControl.value.slice(0, -1),
      });
    }

    this.onInvalidForm.emit(+this.inputMinControl.value > +this.inputMaxControl.value);
    this.hasError = false;
  }
}
