import { LockUserTabTrade } from '../models/trades.model';
import { SupplierTradeCard } from '../models/suppliers.model';
import { TradeCardTabConfig } from '../models/trade-card-tabs.model';
import { Injectable } from '@angular/core';
import { InviteSupplierStatuses } from '../constants/suppliers.constants';
import { DatesHelper } from '@app/shared/helpers/dates.helper';

// TODO: вынести общие методы TradeService и SuppliersService в AbstractTradeService
@Injectable()
export abstract class AbstractTradeService {
  protected _ownerTimezoneOffset = DatesHelper.getLocalTimezoneOffset();
  abstract tradeTabs: TradeCardTabConfig[];

  /**
   * Сохраняем значение разницы от UTC владельца кт
   * @param {number} offset
   */
  set ownerTimezoneOffset(offset: number) {
    this._ownerTimezoneOffset = offset || this._ownerTimezoneOffset;
  }

  abstract updateTabsLocking(tradeCardTabs: TradeCardTabConfig[], locking: LockUserTabTrade[]): void;
  abstract storeTab(tab: TradeCardTabConfig): void;

  /**
   * Проверяет наличие одного предложения у поставщика
   * @param supplierTradeCard
   */
  hasSuggestion(supplierTradeCard: SupplierTradeCard): boolean {
    let hasValue = false;

    if (!supplierTradeCard.lots) {
      return false;
    }

    supplierTradeCard.lots.forEach((lot) => {
      lot.nomenclatures_provider.forEach((nomenclature) => {
        hasValue = !lot.deleted && nomenclature.criteria_provider.every((criteria) => criteria.value !== null);
      });
    });

    return hasValue;
  }

  /**
   * Проверяет может ли поставщик перейти на вкладку торги
   * @param supplierTradeCard
   */
  isAcceptedTradeInvitation(supplierTradeCard: SupplierTradeCard): boolean {
    return supplierTradeCard.status === InviteSupplierStatuses.ACCEPTED;
  }
}
