import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { Company, CompanyCreate, CompanyUpdate } from 'app/shared/models/company.model';
import { User } from 'app/shared/models/user.model';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { Unit, UserSettings } from '@app/shared/models/user-settings.model';
import { TIMEZONES } from '@app/shared/constants/date.constants';
import { DatesHelper } from '@app/shared/helpers/dates.helper';
import { NotificationsService } from 'angular2-notifications';
import { mapToObject } from '@app/shared/utils';
import { DutyUsersFilterInterface } from '@app/+employees/shared/interfaces/duty-users-filter.interface';

@Injectable()
export class PersonalDataService {
  constructor(private http: HttpClient, private authService: AuthService, private notify: NotificationsService) {}

  createCompany(company: CompanyCreate): Observable<Company> {
    return this.http.post(`${environment.api_url}/companies`, company).pipe(
      map((data) => {
        this.authService.updateUser();
        return data as Company;
      })
    );
  }

  updateCompany(company: CompanyUpdate): Observable<Company> {
    return this.http
      .put(`${environment.api_url}/company/${company.id}`, company)
      .pipe(map((c: Company) => new Company(c)));
  }

  disableCompany(companyId: number): Observable<any> {
    return this.http.post(`${environment.api_url}/company/${companyId}/disable`, null);
  }

  setPersonalData(data): Observable<any> {
    return this.http.put(`${environment.api_url}/user/${this.authService.user_id}`, data);
  }

  getReadyTso(queryParams?: DutyUsersFilterInterface): Observable<Array<User>> {
    const fromObject = mapToObject(queryParams || {});
    const params = new HttpParams({ fromObject });

    return this.http
      .get<Array<User>>(`${environment.api_url}/users/ready_tso`, { params })
      .pipe(map((users: Array<User>) => users.map((user) => new User(user))));
  }

  bindTsoForUser(tso_id: number): Observable<User> {
    return this.http
      .put(`${environment.api_url}/user/${this.authService.user_id}`, {
        tso_id,
      })
      .pipe(map((user: User) => user));
  }

  getClients(): Observable<Array<User>> {
    return this.http
      .get<Array<User>>(`${environment.api_url}/user/${this.authService.user_id}/tso_clients`)
      .pipe(map((users: Array<User>) => users.map((user) => new User(user))));
  }

  unbindUserByTso(user_id: number) {
    return this.http.post(`${environment.api_url}/user/${user_id}/tso_clients`, {
      refuse_tso: true,
    });
  }

  updateUserData(data): Observable<User> {
    if (data.company) {
      delete data.company.postal_address.isSelected;
      delete data.company.registration_address.isSelected;
    }

    return this.http.put<User>(`${environment.api_url}/user/${this.authService.user_id}`, data);
  }

  getSelfTso(): Observable<User> {
    return this.http
      .get(`${environment.api_url}/user/${this.authService.user_id}`)
      .pipe(map((user) => new User(user).tso));
  }

  getUserSettings(): Observable<UserSettings> {
    return this.http.get<UserSettings>(`${environment.api_url}/user/settings`).pipe(
      map((userSettings: UserSettings) => new UserSettings(userSettings)),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  updateUserSettings(settings: UserSettings): Observable<UserSettings> {
    return this.http.put<UserSettings>(`${environment.api_url}/user/settings`, settings);
  }

  getUnits(): Observable<Unit[]> {
    return this.http.get<Unit[]>(`${environment.api_url}/units`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  showCurrentDatesNoti() {
    const offset: number = this.authService.getTimezoneOffset();
    const timezoneString = TIMEZONES.find((item) => item.value === +offset).name;
    this.notify.success(
      'Success',
      `Ваш часовой пояс ${timezoneString} Текущие дата и время ${DatesHelper.formatDateTime(
        new Date().toString(),
        offset
      )}`
    );
  }
}
