import { Pipe, PipeTransform } from '@angular/core';
import { DatesHelper } from '@app/shared/helpers/dates.helper';

@Pipe({
  name: 'dateShortFormat',
})
export class DateShortFormatPipe implements PipeTransform {
  transform(value: any, offset?: any): any {
    if (!value) {
      return '-';
    }
    return DatesHelper.formatDateShort(value, offset);
  }
}
