import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { ListModalInjector } from '@app/modal-injector/models/modal-injector.model';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ModalInjectorService implements OnDestroy {
  arrayModal: ListModalInjector[] = [];
  id = 1;

  public modalInjectSubject = new Subject<any>();
  public modalInject$ = this.modalInjectSubject.asObservable();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addModal(name_temp: ModalInjectorConstants, param: any = {}) {
    this.modalInject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((array) => {
      if (array.length === 0) {
        this.arrayModal.length = 0;
      }
    });

    if (!!this.arrayModal.length && this.arrayModal[0].name_temp === ModalInjectorConstants.DATEPICKER_WIDGET) {
      this.checkModalsLength();
    } else if (!!this.arrayModal.find((item) => item.name_temp === ModalInjectorConstants.SELECT_DATE)) {
      this.arrayModal.length = 0;
    }

    this.arrayModal.push({
      id: this.id,
      componentClass: {
        id: this.id,
        ...param,
      },
      name_temp,
    });
    this.id++;
    this.modalInjectSubject.next(this.arrayModal);
  }

  findTopElem() {
    return !!this.arrayModal.find((item) => item.alwaysTop);
  }

  closeModal(id: number = 1, all: boolean = false) {
    if (all) {
      this.arrayModal.length = 0;
      this.modalInjectSubject.next(this.arrayModal);
    }
    const blockElem = [...this.arrayModal].reverse().find((item) => item.blockCloseWindow),
      elemDelete = this.arrayModal.find((item) => item.id === id);
    if (!blockElem || blockElem.id <= id || elemDelete.closeAnyClick) {
      setTimeout(() => {
        const indexDelete = this.arrayModal.findIndex((item) => item.id === id);
        // tslint:disable-next-line:no-bitwise
        if (~indexDelete) {
          // tslint:disable-next-line:no-shadowed-variable
          const indexDelete = this.arrayModal.findIndex((item) => item.id === id);
          this.arrayModal.splice(indexDelete, 1);
          this.modalInjectSubject.next(this.arrayModal);
        }
      }, 0);
    }
  }

  checkModalsLength(): void {
    if (this.arrayModal.length > 1) {
      this.arrayModal.pop();
    }
  }
}
