import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DoubleRangeNumberFilterParams } from '@app/shared/models/filters.model';

@Component({
  selector: 'app-range-number-filter',
  templateUrl: './range-number-filter.component.html',
  styleUrls: ['./range-number-filter.component.scss'],
})
export class RangeNumberFilterComponent implements OnInit, OnChanges {
  @Input() value: DoubleRangeNumberFilterParams = {
    from: null,
    to: null,
  };

  @Input() label: string;

  localValue: DoubleRangeNumberFilterParams;

  @Output() submitEvent = new EventEmitter<DoubleRangeNumberFilterParams>();

  constructor() {}

  ngOnInit() {
    this.localValue = Object.assign({}, this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { value } = changes;

    if (value && value.currentValue) {
      this.localValue = Object.assign({}, this.value);
    }
  }

  apply() {
    this.submitEvent.emit(this.localValue);
  }
}
